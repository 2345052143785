import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import './diary.css'
import axios from "axios";
import Navbar from "../Utils/Nav";
import Loader from "../Utils/LoaderPage";
import {Helmet} from "react-helmet";
import logo from '../lib/other/logo.png'
import Calendar from "../Utils/Calendar";
import {VictoryBar, VictoryChart} from "victory-chart";

export default function Pages() {
    const thisPage = `https://dnevnik-rg.ru/api`
    const [user, setUser] = useState({})
    const [students, setStudents] = useState(true)
    const [coaches, setCoaches] = useState(true)
    const [analyticsForStudent, setAnalyticsForStudent] = useState()
    const [analytics, setAnalytics] = useState(true)
    const [tData, setTData] = useState({})
    const [pData, setPData] = useState({})
    const [LesCom, setLesCom] = useState()
    const [calendar, setCalendar] = useState([])
    const [mark, setMark] = useState()
    const [stWas, setStWas] = useState()
    const [activeID, setActiveId] = useState()
    const [finalAnalyticsFinances, setFinalAnalyticsFinances] = useState()
    const [finalAnalyticsUsers, setFinalAnalyticsUsers] = useState()
    const [finalAnalyticsClasses, setFinalAnalyticsClasses] = useState()
    const [finalStudentAnalyticsClasses, setFinalStudentAnalyticsClasses] = useState()
    const [fixedStudents, setFixedStudents] = useState([])
    const [fstPre, setFSTPRE] = useState(false)
    const [analyticsSelectedStudent, setAnalyticsSelectedStudent] = useState()
    const date = new Date()
    let thisMonth = (date.getMonth() + 1 > 9) ? date.getMonth() + 1 : "0" + (date.getMonth() + 1).toString()
    let [preload, setPreload] = useState(true)

    function createStudent() {
        axios.get(`${thisPage}/main.php?type=createuser&_r=${window.location.origin}/defaulticon.png&id=${localStorage.getItem('clid')}&name=${pData.name}&sname=${pData.sname}&ot=${pData.ot}&email=${pData.email}&phone=${pData.phone}&sphone=${pData.sphone}&city=${pData.city}&place=${pData.cityW}&by=${pData.char}&bday=${pData.bday}&about=${pData.about}&role=student`)
            .then(res => {
                if (res.data.ok) window.location.href = ''
                else alert('Произошла ошибка\nКод ошибки: ' + res.data.type)
            })
    }

    function getDayOfWeek(date) {
        const dayOfWeek = new Date(date).getDay();
        return isNaN(dayOfWeek) ? null :
            ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'][dayOfWeek];
    }

    function getMonthName(monthNumber) {
        const date = new Date();
        date.setMonth(monthNumber - 1);
        return date.toLocaleString([], {month: 'long'});
    }

    function addTimes(timeArray) {
        let mins = timeArray.reduce((acc, time) => {
            let [h, m] = time.split('.');
            acc += h * 60 + m * 1;
            return acc;
        }, 0);
        return (mins / 60 | 0) + '.' + ('0' + (mins % 60)).slice(-2);
    }

    function today() {
        let today = new Date()
        let dd = String(today.getDate()).padStart(2, '0')
        let mm = String(today.getMonth() + 1).padStart(2, '0')
        let yyyy = today.getFullYear()
        today = yyyy + '-' + mm + '-' + dd
        return today
    }

    function clearAddTimes(time) {
        return time.split('.')[0] < 10 ? '0' + time.split('.')[0] + '.' + time.split('.')[1] : time
    }

    var a = []
    for (let i = 0; i < 27; ++i) {
        a.push({d: date.getDate() + i, i: i})
    }
    const [dates] = useState(a)

    function createTrainer() {
        axios.get(`${thisPage}/main.php?type=createuserC&_r=${window.location.origin}/defaulticon.png&id=${localStorage.getItem('clid')}&name=${tData.name}&sname=${tData.sname}&ot=${tData.ot}&email=${tData.email}&phone=${tData.phone}&city=${tData.city}&place=${tData.cityW}&bday=${tData.bday}&about=${tData.about}&role=coach`)
            .then(response => {
                if (response.data.ok) window.location.href = ''
                else {
                    console.log(response.data.type)
                    alert('Возникла проблема при создании тренера')
                }
            })
    }

    async function getall_() {
        let clid = localStorage.getItem('clid')
        let key = localStorage.getItem('key')
        const re = await axios.get(`${thisPage}/main.php?type=checkuser&id=${clid}&pass=${key}`)
        if (re.data.ok) setUser(JSON.parse(JSON.stringify(re.data.data)))
    }

    function getHistory() {
        axios.get(`${thisPage}/main.php?type=gethistory&user=${user.name + " " + user.sname}&role=${user.role}&today=${date.getFullYear()}-${thisMonth}-${dates[0].d}`)
            .then(rs => {
                if (rs.data.ok) {
                    let newCalen = {}
                    rs.data.data.forEach(el => {
                        if (!newCalen[el.date]) {
                            newCalen[el.date] = [el]
                        } else {
                            // newCalen[el.date] = [el]
                            newCalen[el.date].push(el)
                        }
                    })
                    setCalendar(newCalen)
                } else {
                    console.log(rs.data.type)
                }
            })
    }

    function getStudentsAll() {
        axios.get(`${thisPage}/main.php?type=getglobalstudents&id=${localStorage.getItem('clid')}`)
            .then(rs => {
                if (!rs.data.ok) setStudents(rs.data)
            })
    }

    function getCoachesAll() {
        axios.get(`${thisPage}/main.php?type=getglobalcoaches&id=${localStorage.getItem('clid')}`)
            .then(rs => {
                if (rs.data.ok) setCoaches(rs.data.data)
                else {
                    console.log(rs.data.type)
                }
            })
    }

    function getAnalyticsForStudent(stKey) {
        const d = new Date()
        d.setMonth(d.getMonth() - 5)
        let leastD = d.getFullYear() + '-' + (d.getMonth() < 10 ? '0' + (d.getMonth()) : (d.getMonth())) + '-' + '01'
        axios.get(`${thisPage}/main.php?type=getAnalyticsForStudent&id=${stKey}&dateLeast=${leastD}`)
            .then(rs => {
                if (rs.data.ok) {
                    setAnalyticsForStudent(rs.data.data)
                    let finalArrayCl = []
                    Object.keys(rs.data.data.cl).forEach(function (key, index) {
                        finalArrayCl.push({
                            x: getMonthName(Number(key) < 10 ? '0' + key : key),
                            y: rs.data.data.cl[key]
                        })
                    })
                    setFinalStudentAnalyticsClasses(finalArrayCl)
                } else {
                    console.log(rs.data.type)
                }
            })
    }

    function getAnalytics() {
        const d = new Date()
        d.setMonth(d.getMonth() - 5)
        let leastD = d.getFullYear() + '-' + (d.getMonth() < 10 ? '0' + (d.getMonth()) : (d.getMonth())) + '-' + '01'
        axios.post(`${thisPage}/main.php?type=getAnalytics&currentMonth=${date.getMonth() + 1}&id=${localStorage.getItem('clid')}&coachN=${user.name + " " + user.sname}&role=${user.role}&leastD=${leastD}`)
            .then(rs => {
                if (rs.data.ok) {
                    setAnalytics(JSON.parse(JSON.stringify(rs.data)))
                    let finalArrayFin = [], finalArrayCl = [], finalArrayUs = []
                    Object.keys(rs.data.advanced.incM).forEach(function (key, index) {
                        finalArrayFin.push({
                            x: getMonthName(Number(key) < 10 ? '0' + key : key),
                            y: rs.data.advanced.incM[key] / 1000
                        })
                    })
                    Object.keys(rs.data.advanced.su).forEach(function (key, index) {
                        finalArrayUs.push({x: getMonthName(key), y: rs.data.advanced.su[key]})
                    })
                    Object.keys(rs.data.advanced.cl).forEach(function (key, index) {
                        finalArrayCl.push({x: getMonthName(key), y: rs.data.advanced.cl[key]})
                    })
                    setFinalAnalyticsFinances(finalArrayFin)
                    setFinalAnalyticsClasses(finalArrayCl)
                    setFinalAnalyticsUsers(finalArrayUs)
                } else {
                    setAnalytics(false)
                    console.log('Возникла проблема при загрузке аналитики')
                }
            })
    }

    if (!user.name) getall_()
    if (!localStorage.getItem('clid') || !localStorage.getItem('key')) window.location.href = '/auth'
    const P = useParams()
    if (preload && user.name) {
        setPreload(false);
        getHistory();
        getAnalytics()
    }

    function getFixedStudents() {
        axios.get(`${thisPage}/main.php?type=getCoachStudents&coach=${localStorage.getItem('clid')}`)
            .then(response => {
                if (response.data.ok) {
                    setFixedStudents(response.data.data)
                } else {
                    alert('Произошла ошибка загрузки учениц')
                }
                setFSTPRE(true)
            })
    }

    useEffect(() => {
        getStudentsAll()
        if (user?.role === 'admin' || user?.role === 'Sysadmin') {
            getFixedStudents()
            getCoachesAll()
        }
    }, [user])
    if (!user.name) return <Loader/>
    else switch (P.pagetype) {
        case '0':
            return <Calendar role={user.role} user={user}/>
        case 'p': {
            if (user?.role === 'student') return <h1>Вы не имеете доступ к этой странице.</h1>
            else return (
                <div className="App">
                    <Helmet>
                        <title>Страница учениц</title>
                        <link rel="shortcut icon" href={logo}/>
                    </Helmet>
                    <section>
                        <div className="create_pupil_block">
                            <article style={{width: 500, maxWidth: '95%', margin: '50px auto', zIndex: 10}}>
                                <h2 style={{margin: 25, textAlign: "center"}}>Создание аккаунта ученицы</h2>
                                <input onChange={e => setPData(p => ({...p, name: e.target.value}))}
                                       value={pData.name || ''}
                                       placeholder="Имя"/>
                                <input onChange={e => setPData(p => ({...p, sname: e.target.value}))}
                                       value={pData.sname || ''} placeholder="Фамилия"/>
                                <input onChange={e => setPData(p => ({...p, ot: e.target.value}))}
                                       value={pData.ot || ''} placeholder="Отчество"/>
                                <input onChange={e => setPData(p => ({...p, email: e.target.value}))}
                                       value={pData.email || ''} placeholder="E-mail"/>
                                <input onChange={e => setPData(p => ({...p, phone: e.target.value}))}
                                       value={pData.phone || ''} placeholder="Телефон"/>
                                <input onChange={e => setPData(p => ({...p, sphone: e.target.value}))}
                                       value={pData.sphone || ''}
                                       placeholder="Телефон родителей"/>
                                <input onChange={e => setPData(p => ({...p, city: e.target.value}))}
                                       value={pData.city || ''} placeholder="Город"/>
                                <input onChange={e => setPData(p => ({...p, cityW: e.target.value}))}
                                       value={pData.cityW || ''}
                                       placeholder="Место тренировок"/>
                                <input onChange={e => setPData(p => ({...p, bday: e.target.value}))}
                                       value={pData.bday || ''}
                                       placeholder="Дата рождения"/>
                                <textarea onChange={e => setPData(p => ({...p, about: e.target.value}))}
                                          value={pData.about || ''} rows='5'
                                          placeholder="О ученице"/>
                                <textarea onChange={e => setPData(p => ({...p, char: e.target.value}))}
                                          value={pData.char || ''} rows='5'
                                          placeholder="Характеристика от тренера"/>
                                <button onClick={() => createStudent()}>Создать</button>
                            </article>
                        </div>
                        <div className="pupils_list">
                            {!students[0] ? <h3 style={{textAlign: "center"}}>
                                Ученицы не найдены
                            </h3> : students?.map(el => {
                                return (
                                    <article className="pupil_card" style={{marginBottom: 20}}>
                                        <div className="fl alc" style={{marginBlock: 10}}>
                                            <img style={{marginInline: 10}} width='45' src={el.logo}/>
                                            <div>
                                                <h3>{el.name}</h3>
                                                <h3>{el.sname}</h3>
                                            </div>

                                        </div>
                                        <div>
                                            <p onClick={() => {
                                                navigator.clipboard.writeText(el.public_key);
                                                alert(`Login ${el.public_key} Copied`)
                                            }}>Логин: {el.public_key}</p>
                                            <p onClick={() => {
                                                navigator.clipboard.writeText(el.password_key);
                                                alert(`Password ${el.password_key} Copied`)
                                            }}>Пароль: {el.password_key}</p>
                                            <p>Тел. {el.phone}</p>
                                            <p style={{color: 'blue'}}
                                               onClick={() => window.open(`/acc/${el.public_key}`)}>Подробнеe...</p>
                                            <p style={{color: 'red'}} onClick={() => {
                                                if (window.confirm(`Удалить аккаунт ${el.name + el.sname}?`)) {
                                                    axios.get(`${thisPage}/main.php?type=deleteAccount&id=${localStorage.getItem('clid')}&delId=${el.public_key}`).then(res => {
                                                        if (res.data.ok) {
                                                            alert('Аккаунт удален');
                                                            window.location.href = '/diary/p'
                                                        } else {
                                                            alert('Аккаунт не был удален')
                                                            console.log(res.data.type)
                                                        }
                                                    })
                                                }
                                            }}>Удалить</p>
                                        </div>
                                    </article>
                                )
                            })}
                        </div>
                    </section>
                    <Navbar usertype={user.role} fixedT={user.fixed_trainer}/>
                </div>
            )
        }
        case 't':
            if (user?.role === 'admin' || user?.role === 'Sysadmin') return <div className="App">
                <div className="create_pupil_block">
                    <article style={{width: 500, maxWidth: '95%', margin: '50px auto', zIndex: 10}}>
                        <h2 style={{margin: 25, textAlign: "center"}}>Создание аккаунта тренера</h2>
                        <input onChange={e => setTData(p => ({...p, name: e.target.value}))} value={tData.name || ''}
                               placeholder="Имя"/>
                        <input onChange={e => setTData(p => ({...p, sname: e.target.value}))} value={tData.sname || ''}
                               placeholder="Фамилия"/>
                        <input onChange={e => setTData(p => ({...p, ot: e.target.value}))} value={tData.ot || ''}
                               placeholder="Отчество"/>
                        <input onChange={e => setTData(p => ({...p, email: e.target.value}))} value={tData.email || ''}
                               placeholder="E-mail"/>
                        <input onChange={e => setTData(p => ({...p, phone: e.target.value}))} value={tData.phone || ''}
                               placeholder="Телефон"/>
                        <input onChange={e => setTData(p => ({...p, bday: e.target.value}))} value={tData.bday || ''}
                               placeholder="Дата рождения"/>
                        <input onChange={e => setTData(p => ({...p, city: e.target.value}))} value={tData.city || ''}
                               placeholder="Город рождения"/>
                        <input onChange={e => setTData(p => ({...p, cityW: e.target.value}))} value={tData.cityW || ''}
                               placeholder="Место работы"/>
                        <input onChange={e => setTData(p => ({...p, st: e.target.value}))} value={tData.st || ''}
                               placeholder="Стаж работы"/>
                        <textarea onChange={e => setTData(p => ({...p, about: e.target.value}))}
                                  value={tData.about || ''} placeholder="О себе"/>
                        <button onClick={() => createTrainer()}>Создать</button>
                    </article>
                </div>
                <div className="pupils_list">
                    {!coaches[0] ? <h3 style={{textAlign: "center"}}>
                        Тренеры не найдены
                    </h3> : coaches?.map(el => {
                        return (
                            <article className="pupil_card">
                                <div className="fl alc" style={{marginBlock: 10}}>
                                    <img style={{marginInline: 10}} width='45' alt={el.logo} src={el.logo}/>
                                    <div>
                                        <h3>{el.name}</h3>
                                        <h3>{el.sname}</h3>
                                    </div>

                                </div>
                                <div>
                                    <p onClick={() => {
                                        navigator.clipboard.writeText(el.public_key);
                                        alert(`Login ${el.public_key} Copied`)
                                    }}>Логин: {el.public_key}</p>
                                    <p onClick={() => {
                                        navigator.clipboard.writeText(el.password_key);
                                        alert(`Password ${el.password_key} Copied`)
                                    }}>Пароль: {el.password_key}</p>
                                    <p>Тел. {el.phone}</p>
                                    <p style={{color: 'blue'}}
                                       onClick={() => window.open(`/acc/${el.public_key}`)}>Подробнеe...</p>
                                    <p style={{color: 'red'}} onClick={() => {
                                        if (window.confirm(`Удалить аккаунт ${el.name + el.sname}?`)) {
                                            axios.post(`${thisPage}/main.php?type=deleteAccount&id=${localStorage.getItem('clid')}&delId=${el.public_key}`)
                                                .then(res => {
                                                    if (res.data.ok === 'ok') {
                                                        alert('Аккаунт удален');
                                                        window.location.href = '/diary/p'
                                                    } else alert('Аккаунт не был удален')
                                                })
                                        }
                                    }}>Удалить</p>
                                </div>

                            </article>
                        )
                    })}
                </div>
                <Navbar usertype={user.role} fixedT={user.fixed_trainer}/>
            </div>
            else return <div className="App">
                <h2 style={{textAlign: "center", marginTop: 50}}>Вы не имеете доступа к этой странице.</h2>
                <Navbar usertype={user.role}/>
            </div>
        case 'h':
            if (!calendar) return <Loader/>
            else return <div className="App">
                <Helmet>
                    <title>Календарь</title>
                    <link rel="shortcut icon" href={logo}/>
                </Helmet>
                <div className="calendar__">
                    <h1>История занятий</h1>
                    <div className="hist_calen">
                        {user.role === 'student' ? <p>История занятий за сегодняшний день появится завтра.</p> : ''}
                        {Object.keys(calendar).map((key, index) => {
                            return <div>
                                <div className="c__cd">
                                    <div style={{display: 'flex', flexDirection: 'column', padding: 5}}>
                                        <h2>{getDayOfWeek(key)}</h2>
                                        <h3>{key.split('-')[2]}-{key.split('-')[1]}-{key.split('-')[0]}</h3>
                                    </div>
                                </div>
                                {calendar[key].map(day => {
                                    return <div className='day__calen_c' style={{justifyContent: "center"}}>
                                        {user?.role === 'student' ? <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "space-evenly"
                                            }}>
                                                {day.state === '1' ? ((date.getHours() + '.' + date.getMinutes()) > clearAddTimes(addTimes([day.time, day.duration])) && (new Date(today()).getTime() >= new Date(day.date).getTime()) ?
                                                        <h3 style={{color: "green"}}>Занятие завершено</h3> :
                                                        <React.Fragment></React.Fragment>) :
                                                    <h3 style={{color: "orange"}}>Занятие отменено</h3>}
                                                <div style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-start",
                                                    width: 300,
                                                    maxWidth: '85%'
                                                }}>
                                                    <h4 style={{color: 'grey'}}>Тренер: </h4>
                                                    <h3 style={{margin: 5}}>{day.coach}</h3>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-start",
                                                    width: 250,
                                                    maxWidth: '85%'
                                                }}>
                                                    <h4 style={{color: 'grey'}}>Оценка: </h4>
                                                    <h3 style={{margin: 5}}>{day.mark ? day.mark :
                                                        <span style={{color: '#5E5E5E'}}>Не указана</span>}</h3>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-start",
                                                    width: 250,
                                                    maxWidth: '85%'
                                                }}>
                                                    <h4 style={{color: 'grey'}}>Время: </h4>
                                                    <h3 style={{margin: 5}}>{day?.time} - {addTimes([day?.time, day?.duration])}</h3>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-start",
                                                    width: 250,
                                                    maxWidth: '85%'
                                                }}>
                                                    <h4 style={{color: 'grey'}}>Цена: </h4>
                                                    <h3 style={{margin: 5}}>{day.price} ₽</h3>
                                                </div>
                                                <div style={{
                                                    width: 250,
                                                    maxWidth: '85%'
                                                }}>
                                                    <h4 style={{color: 'grey'}}>Комментарий тренера: </h4>
                                                    <h4 style={{margin: 5}}>{day.comment ? day.comment :
                                                        <span style={{color: '#5E5E5E'}}>Отсутствует</span>}</h4>
                                                </div>
                                            </div> :
                                            <div className="fl alc"
                                                 style={{justifyContent: "center", flexDirection: "column"}}>
                                                {day.state === '1' ? (((date.getHours() + '.' + date.getMinutes()) > clearAddTimes(addTimes([day.time, day.duration])) && (new Date(today()).getTime() >= new Date(day.date).getTime()) || (new Date(today()).getTime() >= new Date(day.date).getTime())) ?
                                                        <h3 style={{color: "green"}}>Занятие завершено</h3> :
                                                        <React.Fragment></React.Fragment>) :
                                                    <h3 style={{color: "orange"}}>Занятие отменено</h3>}
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    justifyContent: "space-evenly"
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "flex-start",
                                                        width: 300,
                                                        maxWidth: '85%'
                                                    }}>
                                                        <h4 style={{color: 'grey'}}>Ученица: </h4>
                                                        <h3 style={{padding: 5}}>{day.student}</h3>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "flex-start",
                                                        width: 300,
                                                        maxWidth: '85%'
                                                    }}>
                                                        <h4 style={{color: 'grey'}}>Время: </h4>
                                                        <h3 style={{margin: 5}}>{day.time} - {addTimes([day.time, day.duration])}</h3>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "flex-start",
                                                        width: 300,
                                                        maxWidth: '85%'
                                                    }}>
                                                        <h4 style={{color: 'grey'}}>Цена: </h4>
                                                        <h3 style={{margin: 5}}>{day.price} ₽</h3>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "flex-start",
                                                        width: 250,
                                                        maxWidth: '85%'
                                                    }}>
                                                        <h4 style={{color: 'grey'}}>Присутствие: </h4>
                                                        <select
                                                            style={{
                                                                padding: 5,
                                                                margin: 5,
                                                                borderRight: '1px solid gray'
                                                            }}
                                                            onChange={e => {
                                                                setStWas(e.target.value);
                                                                setActiveId(day.id)
                                                            }}
                                                            value={activeID == day.id ? stWas : day.was}
                                                        >
                                                            <option selected={!day.was} value='' disabled>Опции
                                                            </option>
                                                            <option selected={day.was === 'Была'} value='Была'>Была
                                                            </option>
                                                            <option selected={day.was === 'Не была'} value='Не была'>Не
                                                                была
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "flex-start",
                                                        width: 250,
                                                        maxWidth: '85%'
                                                    }}>
                                                        <h4 style={{color: 'grey'}}>Оценка: </h4>
                                                        <select
                                                            style={{
                                                                padding: 5,
                                                                margin: 5,
                                                                borderRight: '1px solid gray'
                                                            }}
                                                            onChange={e => {
                                                                setMark(e.target.value);
                                                                setActiveId(day.id)
                                                            }}
                                                            value={activeID == day.id ? mark : day.mark}
                                                        >
                                                            <option selected={!day.mark} value='' disabled>Оценка
                                                            </option>
                                                            <option selected={day.mark == '5'} value='5'>5</option>
                                                            <option selected={day.mark == '4'} value='4'>4</option>
                                                            <option selected={day.mark == '3'} value='3'>3</option>
                                                            <option selected={day.mark == '2'} value='2'>2</option>
                                                        </select>
                                                    </div>
                                                    <div style={{
                                                        width: 250,
                                                        maxWidth: '85%'
                                                    }}>
                                                        <h4 style={{color: 'grey'}}>Комментарий тренера: </h4>
                                                        <textarea style={{maxWidth: '95%'}} rows='4'
                                                                  placeholder="Комментарий"
                                                                  onChange={e => {
                                                                      setLesCom(e.target.value);
                                                                      setActiveId(day.id)
                                                                  }}
                                                                  value={activeID == day.id ? LesCom : day.comment}></textarea>
                                                    </div>
                                                </div>
                                                <button style={{width: 'auto', margin: 5}} onClick={() => {
                                                    axios.post(`${thisPage}/main.php?type=updHist&dayId=${activeID}&was=${stWas ? stWas : day.was}&mark=${mark ? mark : day.mark}&comment=${LesCom ? LesCom : day.comment}`)
                                                        .then(res => {
                                                            if (res.data.ok) window.location.href = ''
                                                            else {
                                                                alert("Произошла ошибка")
                                                                console.log(res.data.type)
                                                            }
                                                        })
                                                }}>Сохранить
                                                </button>
                                            </div>}
                                    </div>
                                })}
                            </div>
                        })}
                    </div>
                </div>
                <Navbar usertype={user.role} fixedT={user.fixed_trainer}/>
            </div>
        case 'a':
            if (!analytics.tpc || !fstPre) return <Loader/>
            else return <div className="App">
                <section>
                    <div className="an__section">
                        <h1 style={{marginBlock: 10, textAlign: "center"}}>Аналитика</h1>
                        <section style={{
                            width: 500,
                            maxWidth: '100%'
                        }}>
                            <h3 style={{textAlign: "center"}}>Занятия по месяцам</h3>
                            <p style={{textAlign: "center"}}>Занятия/Месяц</p>
                            <VictoryChart>
                                <VictoryBar
                                    style={{
                                        data: {fill: "#f54100"}
                                    }}
                                    data={finalAnalyticsClasses}>
                                </VictoryBar>
                            </VictoryChart>
                            <p style={{marginBottom: 5}}><span
                                style={{color: 'grey'}}>Последний месяц:</span> {analytics.tpc}</p>
                            <p style={{marginBottom: 5}}><span
                                style={{color: 'grey'}}>Пройдено/осталось:</span> {analytics.tpc - analytics.advanced.clRem}/{analytics.advanced.clRem}
                            </p>
                            <p style={{marginBottom: 5}}><span
                                style={{color: 'grey'}}>Всего:</span> {analytics.apc}</p>
                        </section>
                        <br/>
                        <section style={{
                            width: 500,
                            maxWidth: '100%'
                        }}>
                            <h3 style={{textAlign: "center"}}>Доходы по месяцам</h3>
                            <p style={{textAlign: "center"}}>Доход в т.р./Месяц</p>
                            <VictoryChart>
                                <VictoryBar
                                    style={{
                                        data: {fill: "#F57C00"}
                                    }}
                                    data={finalAnalyticsFinances}>
                                </VictoryBar>
                            </VictoryChart>
                            <p style={{marginBottom: 5}}><span
                                style={{color: 'grey'}}>Последний месяц:</span> {analytics.tpp} ₽</p>
                            <p style={{marginBottom: 5}}><span
                                style={{color: 'grey'}}>Получен/Предстоящий:</span> {analytics.tpp - analytics.advanced.incUpc}/{analytics.advanced.incUpc} ₽
                            </p>
                            <p style={{marginBottom: 5}}><span
                                style={{color: 'grey'}}>Всего:</span> {analytics.app} ₽</p>
                        </section>
                        <br/>
                        <section style={{
                            width: 500,
                            maxWidth: '100%'
                        }}>
                            <h3 style={{textAlign: "center"}}>Новые ученицы</h3>
                            <p style={{textAlign: "center"}}>Регистрации/Месяц</p>
                            <VictoryChart>
                                <VictoryBar
                                    style={{
                                        data: {fill: "#0062f5"}
                                    }}
                                    data={finalAnalyticsUsers}>
                                </VictoryBar>
                            </VictoryChart>
                            <p style={{marginBottom: 5}}><span
                                style={{color: 'grey'}}>Последний месяц:</span> {analytics.tps} учениц</p>
                            <p style={{marginBottom: 5}}><span
                                style={{color: 'grey'}}>Всего:</span> {analytics.aps} учениц</p>
                        </section>
                    </div>
                </section>
                <section>
                    <div className="an__section">
                        <h1 style={{marginBlock: 10, textAlign: "center"}}>Аналитика по ученицам</h1>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            height: '100%',
                            justifyContent: "space-evenly"
                        }}>
                            <select onChange={e => {
                                setAnalyticsSelectedStudent(e.target.value)
                                getAnalyticsForStudent(e.target.value)
                            }}
                                    className="input"
                                    style={{width: 250, maxWidth: '85%'}}
                                    value={analyticsSelectedStudent}>
                                <option selected value={undefined}>Первая ученица</option>
                                {fixedStudents.map(st => {
                                    return <option value={st.public_key}>
                                        {st.name + ' ' + st.sname}
                                    </option>
                                })}
                            </select>
                            {finalStudentAnalyticsClasses &&
                                <section style={{
                                    width: 500,
                                    maxWidth: '100%'
                                }}>
                                    <h3 style={{textAlign: "center"}}>Занятия по месяцам</h3>
                                    <p style={{textAlign: "center"}}>Занятия/Месяц</p>
                                    <VictoryChart>
                                        <VictoryBar
                                            style={{
                                                data: {fill: "#f54100"}
                                            }}
                                            data={finalStudentAnalyticsClasses}>
                                        </VictoryBar>
                                    </VictoryChart>
                                    <p style={{marginBottom: 5}}><span
                                        style={{color: 'grey'}}>Занятий всего: </span> {analyticsForStudent.alTimeCl}
                                    </p>
                                    <p style={{marginBottom: 5}}><span
                                        style={{color: 'grey'}}>Пройдено/осталось (занятий за последний месяц):</span> {analyticsForStudent.tpc - analyticsForStudent.clRem}/{analyticsForStudent.clRem}
                                    </p>
                                    <p style={{marginBottom: 5}}><span
                                        style={{color: 'grey'}}>Пропущенные тренировки (всего/этот месяц):<br/></span>
                                        {analyticsForStudent.skippedClasses}/{analyticsForStudent.tpSkippedClasses}
                                    </p>
                                    <p style={{marginBottom: 5}}><span
                                        style={{color: 'grey'}}>Дата регистрации:</span> {analyticsForStudent.dateReg.split('-')[2]} {analyticsForStudent.dateReg.split('-')[1]} {analyticsForStudent.dateReg.split('-')[0]}
                                    </p>
                                    <p style={{marginBottom: 5}}><span
                                        style={{color: 'grey'}}>Суммарное время тренировок:<br/></span>
                                        {analyticsForStudent.allTime.split('.')[0]} час(-ов) {analyticsForStudent.allTime.split('.')[1]} минут
                                    </p>
                                    <p style={{marginBottom: 5}}><span
                                        style={{color: 'grey'}}>Доход по ученице за месяц:<br/></span>
                                        {analyticsForStudent.tpi} ₽
                                    </p>
                                </section>}
                            <br/>
                        </div>
                    </div>
                </section>
                <Navbar usertype={user.role} fixedT={user.fixed_trainer}/>
            </div>
    }
}