import React, {useEffect, useState} from "react";
import './diary.css'
import axios from "axios";
import Navbar from "../Utils/Nav";
import {Helmet} from "react-helmet";
import logo from '../lib/other/logo.png'

export default function AdminDiary() {
    const [user, setUser] = useState({})
    const [calendar, setCalendar] = useState([])
    const [analytics, setAnalytics] = useState()
    const [stClasses, setStClasses] = useState()
    const [req, setReq] = useState(false)
    let [preload, setPreload] = useState(true)
    var date = new Date()
    const thisPage = `https://dnevnik-rg.ru/api`
    let thisMonth = (date.getMonth() + 1 > 9) ? date.getMonth() + 1 : "0" + (date.getMonth() + 1).toString()

    function getClasses() {
        axios.get(`${thisPage}/main.php?type=getClassesForWeek&s=${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}&id=${localStorage.getItem('clid')}`)
            .then(resp => {
                resp.data.ok ? setCalendar(resp.data.data) : console.log('<No classes imported>')
            })
        console.log(calendar)
    }

    function addTimes(timeArray) {
        let mins = timeArray.reduce((acc, time) => {
            let [h, m] = time.split('.');
            acc += h * 60 + m * 1;
            return acc;
        }, 0);
        return (mins / 60 | 0) + '.' + ('0' + (mins % 60)).slice(-2);
    }

    function getSclasses() {
        axios.get(`${thisPage}/main.php?type=getStudentClasses&st=${user.name + ' ' + user.sname}`)
            .then(resp => {
                if (resp.data.ok) setStClasses(resp.data.data)
                else {
                    console.log('cannot get student classes')
                }
            })
        setReq(true)
    }

    function deleteClass(id) {
        axios.get(`${thisPage}/main.php?type=delClass&id=${localStorage.getItem('clid')}&classId=${id}`)
            .then(resp => {
                if (resp.data.ok) window.location.href = ''
                else if (!resp.data.ok) {
                    alert("Error detected\nCode: " + resp.data.type)
                }
            })
    }

    function getAnalytics() {
        axios.post(`${thisPage}/main.php?type=getAnalytics&currentMonth=${date.getMonth() + 1}&id=${localStorage.getItem('clid')}&coachN=${user.name + " " + user.sname}&role=${user.role}`)
            .then(rs => {
                if (rs.data.ok) setAnalytics(rs.data)
                else {
                    setAnalytics(false)
                    console.log('Возникла проблема при загрузке аналитики')
                }
            })
    }

    function getDayOfWeek(date) {
        const dayOfWeek = new Date(date).getDay();
        return isNaN(dayOfWeek) ? null :
            ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'][dayOfWeek];
    }

    async function getall_() {
        var clid = localStorage.getItem('clid')
        var key = localStorage.getItem('key')
        const re = await axios.get(`${thisPage}/main.php?type=checkuser&id=${clid}&pass=${key}`)
        if (re.data.ok) setUser(JSON.parse(JSON.stringify(re.data.data)))
    }

    function today() {
        let today = new Date()
        let dd = String(today.getDate()).padStart(2, '0')
        let mm = String(today.getMonth() + 1).padStart(2, '0')
        let yyyy = today.getFullYear()
        today = yyyy + '-' + mm + '-' + dd
        return today
    }

    function clearAddTimes(time) {
        return time.split('.')[0] < 10 ? '0' + time.split('.')[0] + '.' + time.split('.')[1] : time
    }

    if (!user.name) getall_()
    if (preload && user.name && user.role != 'student') {
        setPreload(false);
        getAnalytics()
    }
    if (!localStorage.getItem('clid') || !localStorage.getItem('key')) window.location.href = '/auth'
    useEffect(() => {
        getClasses()
    }, [])
    if (user.name && !req) getSclasses()
    switch (user.role) {
        case 'admin':
            return (
                <div className="App">
                    <Navbar usertype={user.role} fixedT={user?.fixed_trainer}/>
                    <Helmet>
                        <title>Главная страница</title>
                        <link rel="shortcut icon" href={logo}/>
                    </Helmet>
                    <div className="main__content">
                        <h1 style={{marginBottom: 15}}>Вы вошли как Администратор</h1>
                        <h2 style={{marginBottom: 15}}>Здравствуйте, {user.name} {user.ots}</h2>
                        <div className="mainp_a">
                            <h2>Аналитика за месяц: </h2>
                            <h3>Доход: {analytics?.tpp} ₽</h3>
                            <h3>Занятия: {analytics?.tpc}</h3>
                            <h3>Ученицы: {analytics?.tps}</h3>
                        </div>
                        <div className="calendar__">
                            <h1>Расписание на сегодня</h1>
                            <a href='/diary/0'><h3 style={{color: "blue"}}>Смотерть календарь занятий</h3></a>
                            <div className="day__calen" style={{justifyContent: "space-around", flexWrap: "wrap"}}>
                                <div className="dc_day" style={{
                                    fontWeight: 500,
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    width: 140
                                }}>
                                    <h3>{getDayOfWeek(Date.now())}</h3>
                                    <h3>{date.getDate()}.{thisMonth}.{date.getFullYear()}</h3>
                                </div>
                            </div>
                            {calendar?.map(d => {
                                return d.date === `${date.getFullYear()}-${thisMonth}-${date.getDate()}` ?
                                    <div className="day__calen_c">
                                        <div style={{textAlign: "center"}}>
                                            {d.state === '1' ? ((date.getHours() + '.' + date.getMinutes()) > clearAddTimes(addTimes([d.time, d.duration])) && (new Date(today()).getTime() >= new Date(d.date).getTime()) ?
                                                    <h4 style={{color: "green"}}>Занятие завершено</h4> :
                                                    <React.Fragment></React.Fragment>) :
                                                <h4 style={{color: "orange"}}>Занятие отменено</h4>}
                                        </div>
                                        <div style={{textAlign: "center"}}>
                                            <h4 style={{color: "grey"}}>{user?.role === 'student' ? 'Тренер' : "Ученица"}:</h4>
                                            <h3 id="nmj2k">{user.role === 'student' ? d.coach : d.student}</h3>
                                        </div>
                                        <div style={{textAlign: "center"}}>
                                            <h4 style={{color: "grey"}}>Время:</h4>
                                            <h3 id="nmj2k">{d.time} - {addTimes([d.time, d.duration])}</h3>
                                        </div>
                                        <div style={{textAlign: "center"}}>
                                            <h4 style={{color: "grey"}}>Цена:</h4>
                                            <h3 id="nmj2k" style={{textAlign: "center"}}>{d.price} ₽</h3>
                                        </div>
                                        <div>
                                            {user.role !== 'student' ? <button style={{
                                                textAlign: "center",
                                                width: 'auto',
                                                marginBottom: 0,
                                                backgroundColor: 'rgba(255, 0, 0, .7)',
                                                color: 'white'
                                            }} onClick={() => {
                                                deleteClass(d.id)
                                            }}>Удалить</button> : <React.Fragment></React.Fragment>}
                                        </div>
                                    </div>
                                    : ''
                            })}
                        </div>
                    </div>
                </div>
            )
        case 'Sysadmin':
            return (
                <div className="App">
                    <Navbar usertype={user.role}/>
                    <Helmet>
                        <title>Главная страница</title>
                        <link rel="shortcut icon" href={logo}/>
                    </Helmet>
                    <div className="main__content">
                        <h1 style={{marginBottom: 15}}>Вы вошли как Сис. Администратор</h1>
                        <h2 style={{marginBottom: 15}}>Здравствуйте, {user.name} {user.ots}</h2>
                        <div className="mainp_a">
                            <h2>Аналитика за месяц: </h2>
                            <h3>Доход: {analytics?.tpp} ₽</h3>
                            <h3>Занятия: {analytics?.tpc}</h3>
                            <h3>Ученицы: {analytics?.tps}</h3>
                        </div>
                        <div className="calendar__">
                            <h1>Расписание на сегодня</h1>
                            <a href='/diary/0'><h3 style={{color: "blue"}}>Смотерть календарь занятий</h3></a>
                            <div className="day__calen" style={{justifyContent: "space-around", flexWrap: "wrap"}}>
                                <div className="dc_day" style={{
                                    fontWeight: 500,
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    width: 140
                                }}>
                                    <h3>{getDayOfWeek(Date.now())}</h3>
                                    <h3>{date.getDate()}.{thisMonth}.{date.getFullYear()}</h3>
                                </div>
                            </div>
                            {calendar?.map(d => {
                                return d.date === `${date.getFullYear()}-${thisMonth}-${date.getDate()}` ?
                                    <div className="day__calen_c">
                                        <div style={{textAlign: "center"}}>
                                            {d.state === '1' ? ((date.getHours() + '.' + date.getMinutes()) > clearAddTimes(addTimes([d.time, d.duration])) && (new Date(today()).getTime() >= new Date(d.date).getTime()) ?
                                                    <h4 style={{color: "green"}}>Занятие завершено</h4> :
                                                    <React.Fragment></React.Fragment>) :
                                                <h4 style={{color: "orange"}}>Занятие отменено</h4>}
                                        </div>
                                        <div>
                                            <h4 style={{color: "grey"}}>{user?.role === 'student' ? 'Тренер' : "Ученица"}:</h4>
                                            <h3 id="nmj2k" style={{
                                                textAlign: "center"
                                            }}>{user.role === 'student' ? d.coach : d.student}</h3>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-evenly",
                                            width: 350,
                                            maxWidth: '100%'
                                        }}>
                                            <div>
                                                <h4 style={{color: "grey"}}>Время:</h4>
                                                <h3 id="nmj2k" style={{
                                                    textAlign: "center"
                                                }}>{d.time}</h3>
                                            </div>
                                            <div>
                                                <h4 style={{color: "grey"}}>Цена:</h4>
                                                <h3 id="nmj2k" style={{textAlign: "center"}}>{d.price} ₽</h3>
                                            </div>
                                            <div>
                                                {user.role !== 'student' ? <button style={{
                                                    width: 'auto',
                                                    marginBottom: 0,
                                                    backgroundColor: 'rgba(255, 0, 0, .7)',
                                                    color: 'white'
                                                }} onClick={() => {
                                                    deleteClass(d.id)
                                                }}>Удалить</button> : <React.Fragment></React.Fragment>}
                                            </div>
                                        </div>
                                    </div>
                                    : ''
                            })}
                        </div>
                    </div>
                </div>
            )
        case 'student':
            return (
                <div className="App">
                    <Navbar usertype={user.role} fixedT={user.fixed_trainer}/>
                    <Helmet>
                        <title>Главная страница</title>
                        <link rel="shortcut icon" href={logo}/>
                    </Helmet>
                    <div className="main__content">
                        <h1 style={{marginBottom: 15}}>Вы вошли как Ученица</h1>
                        <h2 style={{marginBottom: 15}}>Здравствуй, {user.name}!</h2>
                        <div className="mainp_a">
                            <h2>Аналитика за месяц: </h2>
                            <h3>Занятия: {stClasses}</h3>
                            <a href='/sa' style={{color: "blue"}}>Смотреть всю аналитику</a>
                        </div>
                        <div className="calendar__">
                            <h1>Расписание на сегодня</h1>
                            <a href='/diary/0'><h3 style={{color: "blue"}}>Смотерть календарь занятий</h3></a>
                            <div className="day__calen" style={{justifyContent: "space-around", flexWrap: "wrap"}}>
                                <div className="dc_day" style={{
                                    fontWeight: 500,
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    width: 140
                                }}>
                                    <h3>{getDayOfWeek(Date.now())}</h3>
                                    <h3>{date.getDate()}.{thisMonth}.{date.getFullYear()}</h3>
                                </div>
                            </div>
                            {calendar?.map(d => {
                                return d.date === `${date.getFullYear()}-${thisMonth}-${date.getDate()}` ?
                                    <div className="day__calen_c">
                                        <div style={{textAlign: "center"}}>
                                            {d.state === '1' ? ((date.getHours() + '.' + date.getMinutes()) > clearAddTimes(addTimes([d.time, d.duration])) && (new Date(today()).getTime() >= new Date(d.date).getTime()) ?
                                                    <h4 style={{color: "green"}}>Занятие завершено</h4> :
                                                    <React.Fragment></React.Fragment>) :
                                                <h4 style={{color: "orange"}}>Занятие отменено</h4>}
                                        </div>
                                        <div style={{textAlign: "center"}}>
                                            <h4 style={{color: "grey"}}>{user?.role === 'student' ? 'Тренер' : "Ученица"}:</h4>
                                            <h3 id="nmj2k">{user.role === 'student' ? d.coach : d.student}</h3>
                                        </div>
                                        <div style={{textAlign: "center"}}>
                                            <h4 style={{color: "grey"}}>Время:</h4>
                                            <h3 id="nmj2k">{d.time} - {addTimes([d.time, d.duration])}</h3>
                                        </div>
                                        <div style={{textAlign: "center"}}>
                                            <h4 style={{color: "grey"}}>Цена:</h4>
                                            <h3 id="nmj2k" style={{textAlign: "center"}}>{d.price} ₽</h3>
                                        </div>
                                        <div>
                                            {user.role !== 'student' ? <button style={{
                                                textAlign: "center",
                                                width: 'auto',
                                                marginBottom: 0,
                                                backgroundColor: 'rgba(255, 0, 0, .7)',
                                                color: 'white'
                                            }} onClick={() => {
                                                deleteClass(d.id)
                                            }}>Удалить</button> : d.state === '1' ? <button style={{
                                                textAlign: "center",
                                                width: 'auto',
                                                marginBottom: 0,
                                                backgroundColor: '#FF7C14B2',
                                                color: 'white'
                                            }} onClick={() => {
                                                alert('Функция отмены занятия находится в разработке')
                                            }}>Отменить</button> : <React.Fragment></React.Fragment>}
                                        </div>
                                    </div>
                                    : ''
                            })}
                        </div>
                    </div>
                </div>
            )
        case 'coach':
            return (
                <div className="App">
                    <Navbar usertype={user.role}/>
                    <Helmet>
                        <title>Главная страница</title>
                        <link rel="shortcut icon" href={logo}/>
                    </Helmet>
                    <div className="main__content">
                        <h1 style={{marginBottom: 15}}>Вы вошли как Тренер</h1>
                        <h2 style={{marginBottom: 15}}>Здравствуйте, {user.name} {user.ots}</h2>
                        <div className="mainp_a">
                            <h2>Аналитика за месяц: </h2>
                            <h3>Доход: {analytics?.tpp} ₽</h3>
                            <h3>Занятия: {analytics?.tpc}</h3>
                            <h3>Ученицы: {analytics?.tps}</h3>
                        </div>
                        <div className="calendar__">
                            <h1>Расписание на сегодня</h1>
                            <a href='/diary/0' style={{color: "blue"}}><h3>Смотерть календарь занятий</h3></a>
                            <div className="day__calen" style={{justifyContent: "space-around", flexWrap: "wrap"}}>
                                <div className="dc_day" style={{
                                    borderRight: '1px solid lightgray',
                                    fontWeight: 500,
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    width: 140
                                }}>
                                    <h3>{getDayOfWeek(Date.now())}</h3>
                                    <h3>{date.getDate()}.{thisMonth}.{date.getFullYear()}</h3>
                                </div>
                            </div>
                            {calendar?.map(d => {
                                return d.date === `${date.getFullYear()}-${thisMonth}-${date.getDate()}` ?
                                    <div className="day__calen_c">
                                        <div style={{textAlign: "center"}}>
                                            {d.state === '1' ? ((date.getHours() + '.' + date.getMinutes()) > clearAddTimes(addTimes([d.time, d.duration])) && (new Date(today()).getTime() >= new Date(d.date).getTime()) ?
                                                    <h4 style={{color: "green"}}>Занятие завершено</h4> :
                                                    <React.Fragment></React.Fragment>) :
                                                <h4 style={{color: "orange"}}>Занятие отменено</h4>}
                                        </div>
                                        <div style={{textAlign: "center"}}>
                                            <h4 style={{color: "grey"}}>{user?.role === 'student' ? 'Тренер' : "Ученица"}:</h4>
                                            <h3 id="nmj2k">{user.role === 'student' ? d.coach : d.student}</h3>
                                        </div>
                                        <div style={{textAlign: "center"}}>
                                            <h4 style={{color: "grey"}}>Время:</h4>
                                            <h3 id="nmj2k">{d.time} - {addTimes([d.time, d.duration])}</h3>
                                        </div>
                                        <div style={{textAlign: "center"}}>
                                            <h4 style={{color: "grey"}}>Цена:</h4>
                                            <h3 id="nmj2k" style={{textAlign: "center"}}>{d.price} ₽</h3>
                                        </div>
                                        <div>
                                            {user.role !== 'student' ? <button style={{
                                                textAlign: "center",
                                                width: 'auto',
                                                marginBottom: 0,
                                                backgroundColor: 'rgba(255, 0, 0, .7)',
                                                color: 'white'
                                            }} onClick={() => {
                                                deleteClass(d.id)
                                            }}>Удалить</button> : <React.Fragment></React.Fragment>}
                                        </div>
                                    </div>
                                    : ''
                            })}
                        </div>
                    </div>
                </div>
            )
    }
}