import {useState} from "react"
import menu_icon from '../lib/other/menu.png'
import '../Pages/diary.css'

function importAll(r) {
    return r.keys().map(r);
}

const icons = importAll(require.context('../lib/other/logIcons', false, /\.(png)$/))

export default function Navbar(props) {
    const [leftSide_, setLeftSide] = useState(false)
    let exitHref = '/exit_session'
    let profileHref = `/acc/${localStorage.getItem('clid')}`
    switch (props.usertype) {
        case 'admin':
            return (
                <section>
                    <img style={leftSide_ ? {transform: 'rotate(180deg)'} : {transform: 'rotate(0)'}} alt={menu_icon}
                         className="leftSide_activator" onClick={() => setLeftSide(!leftSide_)} src={menu_icon}
                         width='25px'/>
                    <div className={leftSide_ ? "leftSide_menu_hide leftside_active__" : 'leftSide_menu_hide'}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            height: '80vh'
                        }}>
                            <ul>
                                <li>
                                    <a style={{
                                        color: '#e41655'
                                    }} href='/'><h2>Главная</h2></a>
                                </li>
                                <br/>
                                <li>
                                    <a href='/diary'><h2>Дневник</h2></a>
                                </li>
                                <li>
                                    <a href="/diary/a"><h2>Аналитика</h2></a>
                                </li>
                                <li>
                                    <a href="/diary/0"><h2>Календарь</h2></a>
                                </li>
                                <li>
                                    <a href="/diary/h"><h2>История</h2></a>
                                </li>
                                <li>
                                    <a href='/notifications'><h2>Уведомления</h2></a>
                                </li>
                                <li>
                                    <a href="/diary/t"><h2>Тренеры</h2></a>
                                </li>
                                <li>
                                    <a href="/diary/p"><h2>Ученицы</h2></a>
                                </li>
                                <li>
                                    <a href={profileHref}><h2>Профиль</h2></a>
                                </li>
                                <br/>
                                <li>
                                    <a style={{color: "red"}} href={exitHref}><h2>Выйти</h2></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="leftSide__menu">
                        <ul>
                            <li>
                                <a style={{
                                    color: '#e41655'
                                }} href='/'><p>Главная</p></a>
                            </li>
                            <br/>
                            <li>
                                <a href='/diary'><p>Дневник</p></a>
                            </li>
                            <li>
                                <a href="/diary/a"><p>Аналитика</p></a>
                            </li>
                            <li>
                                <a href="/diary/0"><p>Календарь</p></a>
                            </li>
                            <li>
                                <a href="/diary/h"><p>История</p></a>
                            </li>
                            <li>
                                <a href="/notifications"><p>Уведомления</p></a>
                            </li>
                            <li>
                                <a href="/diary/t"><p>Тренеры</p></a>
                            </li>
                            <li>
                                <a href="/diary/p"><p>Ученицы</p></a>
                            </li>
                            <li>
                                <a href={profileHref}><p>Профиль</p></a>
                            </li>
                            <br/>
                            <li>
                                <a href={exitHref}><p style={{color: "red"}}>Выйти</p></a>
                            </li>
                        </ul>
                    </div>
                </section>
            )
        case 'Sysadmin':
            return (
                <section>
                    <img style={leftSide_ ? {transform: 'rotate(180deg)'} : {transform: 'rotate(0deg)'}}
                         className="leftSide_activator" onClick={() => setLeftSide(!leftSide_)} src={menu_icon}
                         alt={menu_icon} width='25px'/>
                    <div className={leftSide_ ? "leftSide_menu_hide leftside_active__" : 'leftSide_menu_hide'}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            height: '80vh'
                        }}>
                            <ul>
                                <li>
                                    <a style={{
                                        color: '#e41655'
                                    }} href='/'><h2>Главная</h2></a>
                                </li>
                                <br/>
                                <li>
                                    <a href='/diary'><h2>Дневник</h2></a>
                                </li>
                                <li>
                                    <a href="/diary/a"><h2>Аналитика</h2></a>
                                </li>
                                <li>
                                    <a href="/diary/0"><h2>Календарь</h2></a>
                                </li>
                                <li>
                                    <a href='/notifications'><h2>Уведомления</h2></a>
                                </li>
                                <li>
                                    <a href="/diary/h"><h2>История</h2></a>
                                </li>
                                <li>
                                    <a href="/diary/t"><h2>Тренеры</h2></a>
                                </li>
                                <li>
                                    <a href="/diary/p"><h2>Ученицы</h2></a>
                                </li>
                                <li>
                                    <a href={profileHref}><h2>Профиль</h2></a>
                                </li>
                                <br/>
                                <li>
                                    <a style={{color: "red"}} href={exitHref}><h2>Выйти</h2></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="leftSide__menu">
                        <ul>
                            <li>
                                <a style={{
                                    color: '#e41655'
                                }} href='/'><p>Главная</p></a>
                            </li>
                            <br/>
                            <li>
                                <a href='/diary'><p>Дневник</p></a>
                            </li>
                            <li>
                                <a href="/diary/a"><p>Аналитика</p></a>
                            </li>
                            <li>
                                <a href="/diary/0"><p>Календарь</p></a>
                            </li>
                            <li>
                                <a href="/diary/h"><p>История</p></a>
                            </li>
                            <li>
                                <a href="/notifications"><p>Уведомления</p></a>
                            </li>
                            <li>
                                <a href="/diary/t"><p>Тренеры</p></a>
                            </li>
                            <li>
                                <a href="/diary/p"><p>Ученицы</p></a>
                            </li>
                            <li>
                                <a href={profileHref}><p>Профиль</p></a>
                            </li>
                            <br/>
                            <li>
                                <a href={exitHref}><p style={{color: "red"}}>Выйти</p></a>
                            </li>
                        </ul>
                    </div>
                </section>
            )
        case 'coach':
            return (
                <section>
                    <img style={leftSide_ ? {transform: 'rotate(180deg)'} : {transform: 'rotate(0)'}} alt={menu_icon}
                         className="leftSide_activator" onClick={() => setLeftSide(!leftSide_)}
                         src={menu_icon} width='25px'/>
                    <div className={leftSide_ ? "leftSide_menu_hide leftside_active__" : 'leftSide_menu_hide'}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            height: '80vh'
                        }}>
                            <ul>
                                <li>
                                    <a style={{
                                        color: '#e41655'
                                    }} href='/'><h2>Главная</h2></a>
                                </li>
                                <br/>
                                <li>
                                    <a href='/diary'><h2>Дневник</h2></a>
                                </li>
                                <li>
                                    <a href="/diary/0"><h2>Календарь</h2></a>
                                </li>
                                <li>
                                    <a href="/diary/a"><h2>Аналитика</h2></a>
                                </li>
                                <li>
                                    <a href='/notifications'><h2>Уведомления</h2></a>
                                </li>
                                <li>
                                    <a href="/diary/p"><h2>Ученицы</h2></a>
                                </li>
                                <li>
                                    <a href="/diary/h"><h2>История</h2></a>
                                </li>
                                <li>
                                    <a href={profileHref}><h2>Профиль</h2></a>
                                </li>
                                <br/>
                                <li>
                                    <a style={{color: "red"}} href={exitHref}><h2>Выйти</h2></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="leftSide__menu">
                        <ul>
                            <li>
                                <a style={{
                                    color: '#e41655'
                                }} href='/'><p>Главная</p></a>
                            </li>
                            <br/>
                            <li>
                                <a href='/diary'><p>Дневник</p></a>
                            </li>
                            <li>
                                <a href="/diary/0"><p>Календарь</p></a>
                            </li>
                            <li>
                                <a href="/diary/a"><p>Аналитика</p></a>
                            </li>
                            <li>
                                <a href="/notifications"><p>Уведомления</p></a>
                            </li>
                            <li>
                                <a href="/diary/p"><p>Ученицы</p></a>
                            </li>
                            <li>
                                <a href="/diary/h"><p>История</p></a>
                            </li>
                            <li>
                                <a href={profileHref}><p>Профиль</p></a>
                            </li>
                            <br/>
                            <li>
                                <a href={exitHref}><p style={{color: "red"}}>Выйти</p></a>
                            </li>
                        </ul>
                    </div>
                </section>
            )
        case 'student':
            let baseCoach = `/acc/${props.fixedT}`
            return (
                <section>
                    <img style={leftSide_ ? {transform: 'rotate(180deg)'} : {transform: 'rotate(0)'}} alt={menu_icon}
                         className="leftSide_activator" onClick={() => setLeftSide(!leftSide_)}
                         src={menu_icon} width='25px'/>
                    <div className={leftSide_ ? "leftSide_menu_hide leftside_active__" : 'leftSide_menu_hide'}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            height: '80vh'
                        }}>
                            <ul>
                                <li>
                                    <a style={{
                                        color: '#e41655'
                                    }} href='/'><h2>Главная</h2></a>
                                </li>
                                <br/>
                                <li>
                                    <a href='/diary'><h2>Дневник</h2></a>
                                </li>
                                <li>
                                    <a href="/diary/0"><h2>Календарь</h2></a>
                                </li>
                                <li>
                                    <a href="/diary/h"><h2>История</h2></a>
                                </li>
                                <li>
                                    <a href='/sa'><h2>Аналитика</h2></a>
                                </li>
                                <li>
                                    <a href='/notifications'><h2>Уведомления</h2></a>
                                </li>
                                <li>
                                    <a href={baseCoach}><h2>Тренер</h2></a>
                                </li>
                                <li>
                                    <a href="/rules.pdf"><h2>Информация</h2></a>
                                </li>
                                <li>
                                    <a href={profileHref}><h2>Профиль</h2></a>
                                </li>
                                <br/>
                                <li>
                                    <a style={{color: "red"}} href={exitHref}><h2>Выйти</h2></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="leftSide__menu">
                        <ul>
                            <li>
                                <a style={{
                                    color: '#e41655'
                                }} href='/'><p>Главная</p></a>
                            </li>
                            <br/>
                            <li>
                                <a href='/diary'><p>Дневник</p></a>
                            </li>
                            <li>
                                <a href="/diary/0"><p>Календарь</p></a>
                            </li>
                            <li>
                                <a href="/diary/h"><p>История</p></a>
                            </li>
                            <li>
                                <a href='/sa'><p>Аналитика</p></a>
                            </li>
                            <li>
                                <a href="/notifications"><p>Уведомления</p></a>
                            </li>
                            <li>
                                <a href={baseCoach}><p>Тренер</p></a>
                            </li>
                            <li>
                                <a href="/rules.pdf"><p>Информация</p></a>
                            </li>
                            <li>
                                <a href={profileHref}><p>Профиль</p></a>
                            </li>
                            <br/>
                            <li>
                                <a href={exitHref}><p style={{color: "red"}}>Выйти</p></a>
                            </li>
                        </ul>
                    </div>
                </section>
            )
    }
}