import React, {useEffect, useState} from "react"
import axios from "axios"
import Navbar from "../Utils/Nav";
import Loader from "../Utils/LoaderPage";

export default function Notifications() {
    const thisPage = `https://dnevnik-rg.ru/api`
    const [notifications, setNotifications] = useState([])
    const [user, setUser] = useState({})

    async function getNotifications() {
        const request = await axios.get(`${thisPage}/main.php?type=getNotif&to=${localStorage.getItem('clid')}`)
        if (request.data.ok) {
            setNotifications(request.data.data)
        } else {
            if (request.data.type === 'notifications not found') {
            } else {
                alert('Произошла ошибка загрузки уведолмений')
                console.log(request.data.type)
            }
        }
    }

    async function getall_() {
        let clid = localStorage.getItem('clid')
        let key = localStorage.getItem('key')
        const re = await axios.get(`${thisPage}/main.php?type=checkuser&id=${clid}&pass=${key}`)
        if (re.data.ok) setUser(JSON.parse(JSON.stringify(re.data.data)))
    }

    useEffect(() => {
        getNotifications()
    }, [])
    if (!user.name) getall_()
    if (!user.name) return <Loader/>
    else return <div className="App">
        <section className='an__section'>
            <article>
                <h1 style={{textAlign: "center"}}>Мои уведомления</h1>
            </article>
            <div>
                {notifications ? notifications.map(not => {
                    return <div className='day__calen_c' style={{flexDirection: "column", alignItems: "flex-start"}}>
                        <h3><span style={{marginBlock: 10, color: 'grey'}}>Дата:</span> {not.date}</h3><br/>
                        <h4><span style={{marginBlock: 10, color: 'grey'}}>Описание:</span> {not.type}</h4>
                    </div>
                }) : <p style={{textAlign: "center", marginBlock: 10, color: 'grey'}}>Уведомления отсутствуют</p>}
            </div>
        </section>
        <Navbar usertype={user.role} fixedT={user.fixed_trainer}/>
    </div>
}