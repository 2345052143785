import { Helmet } from 'react-helmet'
import logo from '../lib/other/logo.png'
import { useState } from 'react'
import axios from 'axios'
import './diary.css'
export default function Auth() {
    const [id, setId] = useState()
    const [pass, setPass] = useState()
    const [agree, setAgree] = useState(false)
    const thisPage = `https://dnevnik-rg.ru/api`
    if (localStorage.getItem('key') && localStorage.getItem('clid')) window.location.href = '/diary/'
    async function req() {
        const request = await axios.get(`${thisPage}/main.php?type=checkuser&id=${id}&pass=${pass}`)
        if (!request.data.ok) {
            if (request.data.type === 'cannot fetch server data') alert('Неверный логин или пароль.')
            if (request.data.type === 'unknown DB error') alert('Произошла ошибка базы данных. Повторите попытку позже.')
        } else if (request.data.ok) {
            localStorage.setItem('clid', request.data.data.public_key)
            localStorage.setItem('key', request.data.data.password_key)
            window.location.href = `/diary/`
        } else {

        }
    }
    return (
        <div className="App">
            <Helmet>
                <title>Авторизация</title>
                <link rel="shortcut icon" href={logo} />
                <link
                    rel="stylesheet"
                    href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
                />
            </Helmet>
            <section className="auth__main" id='active__section'>
                <div className="auth-box animate__animated animate__fadeInUp">
                    <h1 style={{ marginBlock: 15 }}>Авторизация</h1>
                    <div>
                        <input value={id} onChange={e => setId(e.target.value)} placeholder="Ваш ID" />
                        <input value={pass} onChange={e => setPass(e.target.value)} placeholder="Пароль" />
                        <div style={{ display: 'inline-block' }}>
                            <label style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                <input onChange={() => setAgree(!agree)} value={agree} id='l12' style={{ margin: 5, width: 15 }} type='checkbox' />
                                <p style={{ width: 250 }}>Согласен(-на) с <a style={{color: 'blue'}} href='rules.pdf'>Условиями пользования</a></p>
                            </label>
                        </div>
                        <button className={!agree ? 'btn-disabled' : ""} onClick={() => agree ? req() : ''}>Войти</button>
                    </div>
                </div>
            </section>
        </div>
    )
}