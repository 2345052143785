import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Navbar from "../Utils/Nav";
import axios from "axios";
import './diary.css'
import Loader from "../Utils/LoaderPage";
import {Helmet} from "react-helmet";
import logo from '../lib/other/logo.png'

export default function Account() {
    let P = useParams()
    const thisPage = `https://dnevnik-rg.ru/api`
    const [user, setUser] = useState({})
    let [Prole, setProle] = useState()

    function getall_() {
        let clid = P.clid
        axios.get(`${thisPage}/main.php?type=preloadPage&id=${clid}&whom=${localStorage.getItem('clid')}`)
            .then(rs => {
                if (rs.data.ok) {
                    setUser(JSON.parse(rs.data.user));
                    setProle(rs.data.parent_role)
                } else if (!rs.data.ok) console.log(rs.data)
            })
    }

    function updateUser() {
        axios.get(`${thisPage}/main.php?type=updUser&public_key=${user.public_key}
        &parentChange=${user.fixed_trainer}&email=${user.email}&phone=${user.phone}
        &s_phone=${user.s_phone}&city=${user.city}&trainig_place=${user.trainig_place}&bday=${user.bday}
        &about=${user.about}&byt=${user.byt}`)
            .then(res => {
                if (res.data.ok) window.location.href = ''
                else alert('Произошла ошибка')
            })
    }

    useEffect(() => {
        getall_()
    }, [])

    if (!user.name) return <Loader/>
    else return <div className="App">
        <Helmet>
            <title>{user.name} {user.sname}</title>
            <link rel="shortcut icon" href={logo}/>
        </Helmet>
        {Prole !== 'student' && <button onClick={() => updateUser()} className='button-save-profile'>Сохранить</button>}
        <section>
            <article className="profile_card_top">
                <h2>ID: {user.public_key}</h2>
                <div className="fl alc">
                    <img alt={user?.logo} style={{margin: 10}} src={user.logo} width='70'/>
                    <div>
                        <h2>{user.sname}</h2>
                        <h2>{user.name}</h2>
                    </div>
                </div>
                <div id="j23n4">
                    <p>E-mail: {Prole === 'student' ? user.email : <input
                        onChange={e => {
                            setUser(p => ({...p, email: e.target.value}))
                        }}
                        style={{width: 'auto', marginBlock: 5}}
                        value={user.email}/>}</p>
                    <p>Телефон: {Prole === 'student' ? user.phone : <input
                        onChange={e => {
                            setUser(p => ({...p, phone: e.target.value}))
                        }}
                        style={{width: 'auto', marginBlock: 5}}
                        value={user.phone}/>}</p>
                    {user.role === 'student' ?
                        <p>Телефон родителей: {Prole === 'student' ? user.s_phone : <input
                            onChange={e => {
                                setUser(p => ({...p, s_phone: e.target.value}))
                            }}
                            style={{width: 'auto', marginBlock: 5}} value={user.s_phone}/>}
                        </p> :
                        <React.Fragment></React.Fragment>}
                    {user.role === 'student' ? <p>ID Тренера: {user.fixed_trainer}</p> :
                        <React.Fragment></React.Fragment>}
                    <p>Город: {Prole === 'student' ? user.city : <input
                        onChange={e => {
                            setUser(p => ({...p, city: e.target.value}))
                        }}
                        style={{width: 'auto', marginBlock: 5}}
                        value={user.city}/>}</p>
                    <p>Место тренировок: {Prole === 'student' ? user.trainig_place : <input
                        onChange={e => {
                            setUser(p => ({...p, trainig_place: e.target.value}))
                        }}
                        style={{width: 'auto', marginBlock: 5}}
                        value={user.trainig_place}/>}</p>
                    <p>Дата рождения: {Prole === 'student' ? user.bday : <input
                        onChange={e => {
                            setUser(p => ({...p, bday: e.target.value}))
                        }}
                        style={{width: 'auto', marginBottom: 0}}
                        value={user.bday}/>}</p>
                </div>
            </article>
            <article className="profile_card_top"
                     style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                <h2 style={{
                    textAlign: "center",
                    marginBlock: 5
                }}>{user.role === 'student' ? 'О ученице' : 'О себе'}</h2>
                {Prole === 'student' ? user.about : <textarea
                    onChange={e => {
                        setUser(p => ({...p, about: e.target.value}))
                    }}
                    rows='3'
                    style={{width: 300, maxWidth: '100%', margin: '0 auto'}}
                    value={user.about}>
                </textarea>}
            </article>
            {user?.role === 'student' ?
                <article className="profile_card_top"
                         style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                    <h2 style={{textAlign: "center", marginBlock: 5}}>Характеристика от тренера</h2>
                    {Prole === 'student' ? user.byt : <textarea
                        onChange={e => {
                            setUser(p => ({...p, byt: e.target.value}))
                        }}
                        style={{width: 300, maxWidth: '100%', margin: '0 auto'}}
                        rows='4'
                        value={user.byt}></textarea>}
                </article> :
                <React.Fragment></React.Fragment>}
        </section>
        <Navbar usertype={Prole} fixedT={user?.role !== 'student' ? user.public_key : ''}/>
    </div>
}