import React, {useEffect, useState} from "react"
import axios from "axios";
import Loader from "../Utils/LoaderPage";
import logo from "../lib/other/logo.png";
import {Helmet} from "react-helmet";
import {VictoryBar, VictoryChart} from "victory-chart";
import Navbar from "../Utils/Nav";

export default function StudentAnalytics() {
    const thisPage = `https://dnevnik-rg.ru/api`
    const [user, setUser] = useState({})
    const [analyticsForStudent, setAnalyticsForStudent] = useState()
    const [finalStudentAnalyticsClasses, setFinalStudentAnalyticsClasses] = useState()

    function getMonthName(monthNumber) {
        const date = new Date();
        date.setMonth(monthNumber - 1);
        return date.toLocaleString([], {month: 'long'});
    }

    async function getall_() {
        let clid = localStorage.getItem('clid')
        let key = localStorage.getItem('key')
        const re = await axios.get(`${thisPage}/main.php?type=checkuser&id=${clid}&pass=${key}`)
        if (re.data.ok) setUser(JSON.parse(JSON.stringify(re.data.data)))
    }

    function getAnalyticsForStudent(stKey) {
        const d = new Date()
        d.setMonth(d.getMonth() - 5)
        let leastD = d.getFullYear() + '-' + (d.getMonth() < 10 ? '0' + (d.getMonth()) : (d.getMonth())) + '-' + '01'
        axios.get(`${thisPage}/main.php?type=getAnalyticsForStudent&id=${stKey}&dateLeast=${leastD}`)
            .then(rs => {
                if (rs.data.ok) {
                    setAnalyticsForStudent(rs.data.data)
                    let finalArrayCl = []
                    Object.keys(rs.data.data.cl).forEach(function (key, index) {
                        finalArrayCl.push({
                            x: getMonthName(key),
                            y: rs.data.data.cl[key]
                        })
                    })
                    setFinalStudentAnalyticsClasses(finalArrayCl)
                } else {
                    console.log(rs.data.type)
                }
            })
    }

    useEffect(() => {
        getAnalyticsForStudent(localStorage.getItem('clid'))
    })
    if (!user.name) {
        getall_()
        return <Loader/>
    } else if (!analyticsForStudent) return <Loader/>
    else return <div className='App'>
            <Helmet>
                <title>Моя аналитика</title>
                <link rel="shortcut icon" href={logo}/>
            </Helmet>
            <section>
                <div className="an__section">
                    <h2 style={{textAlign: "center"}}>{user.name} {user.sname}</h2>
                    <h1 style={{textAlign: "center", marginBottom: 10, fontSize: '1.3rem'}}>Аналитика</h1>
                    <section style={{
                        width: 500,
                        maxWidth: '100%'
                    }}>
                        <h3 style={{textAlign: "center"}}>Занятия по месяцам</h3>
                        <p style={{textAlign: "center"}}>Занятия/Месяц</p>
                        <VictoryChart>
                            <VictoryBar
                                style={{
                                    data: {fill: "#f54100"}
                                }}
                                data={finalStudentAnalyticsClasses}>
                            </VictoryBar>
                        </VictoryChart>
                        <p style={{marginBottom: 5}}><span
                            style={{color: 'grey'}}>Занятий всего: </span> {analyticsForStudent.alTimeCl}
                        </p>
                        <p style={{marginBottom: 5}}><span
                            style={{color: 'grey'}}>Пройдено/осталось (занятий за последний месяц):</span> {analyticsForStudent.tpc - analyticsForStudent.clRem}/{analyticsForStudent.clRem}
                        </p>
                        <p style={{marginBottom: 5}}><span
                            style={{color: 'grey'}}>Пропущенные тренировки (всего/этот месяц):<br/></span>
                            {analyticsForStudent.skippedClasses}/{analyticsForStudent.tpSkippedClasses}
                        </p>
                        <p style={{marginBottom: 5}}><span
                            style={{color: 'grey'}}>Дата регистрации:</span> {analyticsForStudent.dateReg.split('-')[2]} {analyticsForStudent.dateReg.split('-')[1]} {analyticsForStudent.dateReg.split('-')[0]}
                        </p>
                        <p style={{marginBottom: 5}}><span
                            style={{color: 'grey'}}>Суммарное время тренировок:<br/></span>
                            {analyticsForStudent.allTime.split('.')[0]} час(-ов) {analyticsForStudent.allTime.split('.')[1]} минут
                        </p>
                        <p style={{marginBottom: 5}}><span
                            style={{color: 'grey'}}>Траты за месяц:<br/></span>
                            {analyticsForStudent.tpi} ₽
                        </p>
                    </section>
                </div>
            </section>
            <Navbar usertype={user.role} fixedT={user.fixed_trainer}/>
        </div>
}