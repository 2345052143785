import React, {useState} from "react"
import {Helmet} from "react-helmet"
import first from '../lib/other/first.JPG'
import star from '../lib/other/star.png'
import inst_icon from '../lib/socials/free-icon-instagram-1384031.png'
import tg_icon from '../lib/socials/telegram_icon_131945.png'
import vk_icon from '../lib/socials/free-icon-vk-logo-of-social-network-39699.png'
import email_iocn from '../lib/socials/Email_icon-icons.com_55919.png'
import axios from "axios"

function importAll(r) {
    return r.keys().map(r);
}

const images = importAll(require.context('../lib/a', false, /\.(JPG)$/))
const aboutImages = importAll(require.context('../lib/about', false, /\.(JPG)$/))
const aboutImagesF = importAll(require.context('../lib/aboutF', false, /\.(JPG)$/))

export default function Main() {
    const [user, setUser] = useState({})
    const thisPage = `https://dnevnik-rg.ru/api`

    async function firstClass() {
        const req = await axios.get(`${thisPage}/main.php?type=firstclass_main&name=${user.name}&tel=${user.phone}&date=${user.date}`)
        if (req.data.ok) {
            alert("Спасибо за запись! Скоро с вами свяжутся")
        } else {
            alert("Упс... Произошла ошибка, попробуйте позже")
        }
    }

    if (document.getElementById("date")) document.getElementById("date").min = new Date().toISOString().split("T")[0];
    return <div className="App-homescreen">
        <Helmet>
            <script defer src='script.js'></script>
        </Helmet>
        <section className="first-screen">
            <button className="btn-goauth" onClick={() => window.location.href = '/auth'}>Войти</button>
            <div className="first-screen-info">
                <article style={{maxWidth: '100%'}}>
                    <div style={{maxWidth: '100%'}}>
                        <section className="second-screen">
                            <div style={{textAlign: "center"}}>
                                <h1 className="xxl-text"><span className="pink-text">Онлайн</span> тренировки</h1>
                                <h2>По художественной гимнастике</h2>
                            </div>
                            <div style={{display: "flex", alignItems: "center", justifyContent: 'center'}}>
                                <div className="island">
                                    <h4>
                                        Проходят в удобном формате. Вы можете тренироваться из любой точки Мира. Для
                                        этого понадобится мобильное устройство, планшет или ноутбук, всё необходимое для
                                        вашего вида спорта (предметы и инвентарь).
                                    </h4>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div style={{maxWidth: '100%'}}>
                        <h1 className="xxl-text" style={{textAlign: "center"}}>Начни свой путь к <span
                            className="pink-text">победе</span>!</h1>
                        <h2 className="xl-text" style={{textAlign: "center"}}>Вместе с Дарьей Дубовой</h2>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <button className="btn-goauth" style={{margin: 0, marginTop: 10}}
                                    onClick={() => window.location.href = '#first-class'}>Записаться
                            </button>
                        </div>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            animation: 'bounce 1s ease infinite'
                        }}>
                            <div>
                                <p style={{color: "#ABABAB", marginBottom: -14}}>∧</p>
                                <p style={{color: "#ABABAB"}}>∧</p>
                            </div>
                            <p style={{color: "#595959", fontSize: '0.9rem', textAlign: "center"}}>Запишись на первое
                                занятие и получи скидку 10%!</p>
                        </div>
                    </div>
                </article>
                <article>
                    <img className="img-square-cutted" src={first} alt="photo"/>
                </article>
            </div>
        </section>
        <section className="ss-parent"></section>
        <section className="ts-parent">
            <section className="third-screen">
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    maxWidth: '100%',
                    justifyContent: "space-around"
                }}>
                    <div style={{maxWidth: '100%'}}>
                        <h1 className="xxl-text" style={{textAlign: "center"}}>Наша <span
                            className="pink-text">цель</span></h1>
                        <div style={{display: "flex", alignItems: "center", justifyContent: 'center', marginTop: 10}}>
                            <div className="island">
                                <br/>
                                <p style={{fontSize: '1.1rem'}}>&#10004; Cоздать комфортные условия для занятий.</p>
                                <br/>
                                <p style={{fontSize: '1.1rem'}}>
                                    &#10004; Достичь заметного результата уже спустя месяц тренировок.<br/><br/>
                                    &#10004; Проведение наставнических сессий во время тренировок во избежание, как
                                    физического, так и психологического травмирования.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <article className="social-href" style={{display: "flex", alignItems: "center"}}>
                            <img alt="inst_icon" src={inst_icon} style={{width: 35, height: 35, marginInline: 10}}/>
                            <div style={{display: "flex", flexDirection: "column"}}>
                                <p>Instagram</p>
                                <h4><a
                                    href="https://instagram.com/dariadubova__rg.school?igshid=ZWIzMWE5ZmU3Zg==">dariadubova__rg.school</a>
                                </h4>
                            </div>
                        </article>
                        <article className="social-href" style={{display: "flex", alignItems: "center"}}>
                            <img alt="telegram_icon" src={tg_icon} style={{width: 35, height: 35, marginInline: 10}}/>
                            <div style={{display: "flex", flexDirection: "column"}}>
                                <p>Telegram</p>
                                <h4><a href="https://t.me/dariadubova">Daria Dubova</a></h4>
                            </div>
                        </article>
                        <article className="social-href" style={{display: "flex", alignItems: "center"}}>
                            <img alt="vk_icon" src={vk_icon} style={{width: 35, height: 35, marginInline: 10}}/>
                            <div style={{display: "flex", flexDirection: "column"}}>
                                <p>VK</p>
                                <h4><a href="https://vk.com/schoolrgvoronezh">Художественная гимнастика<br/>Тренер
                                    Дубова Дарья</a></h4>
                            </div>
                        </article>
                        <article className="social-href" style={{display: "flex", alignItems: "center"}}>
                            <img alt="email_iocn" src={email_iocn} style={{width: 35, height: 35, marginInline: 10}}/>
                            <div style={{display: "flex", flexDirection: "column"}} onClick={() => {
                                navigator.clipboard.writeText('dubova_dv99@mail.ru');
                                alert(`E - mail Copied`)
                            }}>
                                <p>E-mail</p>
                                <h4>dubova_dv99@mail.ru</h4>
                            </div>
                        </article>
                    </div>
                </div>
            </section>
        </section>
        <section className="about-main">
            <div style={{width: 500, maxWidth: '100%', margin: '0 auto', paddingInline: 15}}>
                <h1 className="xxl-text" style={{textAlign: "center"}}>Дубова <span className="pink-text">Дарья</span>
                </h1>
                <h1 className="xl-text" style={{textAlign: "center"}}>О себе</h1>
            </div>
            <div style={{width: 750, maxWidth: '100%', display: "flex", flexDirection: "column", margin: '25px auto'}}>
                <div id="kj21n4"
                     style={{display: "flex", alignItems: "center", flexWrap: "wrap", justifyContent: "space-around"}}>
                    <div>
                        <div className="slider">
                            {aboutImagesF.map(el => {
                                return <div className="item">
                                    <img className="img-square-cutted" src={el} alt={el}/>
                                </div>
                            })}
                            <a className="previous" id="previous">&lt;</a>
                            <a className="next" id="next">&gt;</a>
                        </div>
                    </div>
                    <div className="island" style={{width: 350, fontSize: '0.8rem'}}>
                        Родилась в 1999 году в городе Воронеж.
                        Карьера по художественной гимнастике началась с 4 лет в СШОР №1 города Воронеж.
                        <br/>Спустя время, Дарью пригласили тренироваться на базу Олимпийского резерва «Динамо-Дмитров»,
                        откуда вскоре ее пригласили в Сборную команду России по групповым упражнениям,
                        для подготовки к Первенству Европы.<br/>На этом Дарья не закончила свою спортивную карьеру,
                        а решила сменить вид спорта!<br/><br/>
                        За всю историю спортивной карьеры, Дарья успела посетить такие страны , как:
                        Китай, Австрия, Португалия, Румыния, Италия, Болгария, Белоруссия, Финляндия, Эстония, Франция,
                        Бразилия и т.д.
                    </div>
                </div>
                <div style={{display: "flex", alignItems: "center", flexWrap: "wrap", justifyContent: "space-around"}}>
                    <div className="island" style={{width: 350, fontSize: '0.75rem'}}>

                        Дарья является Мастером спорта международного класса, член сборной команды России , с 2013-го по
                        2016-ый года.<br/><br/><p style={{
                        textAlign: "center",
                        width: '100%',
                        fontSize: '0.8rem',
                        textDecoration: 'underline',
                        fontWeight: 600
                    }}>Художественная гимнастика.</p><br/>&ndash; Победительница Юношеских Олимпийских
                        Игр<br/><br/>&ndash; Двукратная
                        победительница первенства Европы<br/><br/>&ndash; Победительница международных турниров, этапов
                        кубка Мира и всероссийских соревнований.<br/>
                        Выступала в личной программе и групповых упражнениях.<br/><br/>
                        <p style={{
                            textAlign: "center",
                            width: '100%',
                            fontSize: '0.8rem',
                            textDecoration: 'underline',
                            fontWeight: 600
                        }}>Эстетическая гимнастика.</p><br/>
                        В период с 2017-2020 года выступала за сборную России по эстетической гимнастике<br/><br/>
                        &ndash; Выиграла Чемпионат Мира в командном зачете и бронзу в
                        многоборье<br/><br/>&ndash; Бронзовый призер
                        Чемпионата Европы<br/><br/>&ndash; Серебряный призер Чемпионата
                        России<br/><br/>&ndash; Чемпионка Москвы<br/><br/>&ndash; Призер этапов кубков мира
                    </div>
                    <div>
                        <div className="slider">
                            {aboutImages.map(el => {
                                return <div className="item_1">
                                    <img className="img-square-cutted" src={el} alt={el}/>
                                </div>
                            })}
                            <a className="previous" id="previous_1">&lt;</a>
                            <a className="next" id="next_1">&gt;</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div style={{maxWidth: '100%'}}>
                <div className="form-parent">
                    <div className="form" id='first-class'>
                        <article style={{textAlign: "center", marginBottom: 10}}>
                            <h1 className="xl-text">Записаться</h1>
                            <p className="l-text">Скидка на первое занятие 10%!</p>
                        </article>
                        <div style={{display: 'flex', flexDirection: "column", alignItems: "center"}}>
                            <input onChange={e => setUser(p => ({...p, name: e.target.value.replace(/[^A-zА-я]/, '')}))}
                                   value={user?.name ? user.name : ''} className="main-input" placeholder="Ваше Имя"
                                   type="text"/>
                            <input onChange={e => setUser(p => ({...p, phone: e.target.value.replace(/[^0-9+]/, '')}))}
                                   value={user?.phone ? user.phone : ''} className="main-input"
                                   placeholder="Телефон для связи" type="tel"/>
                            <div style={{
                                display: 'flex',
                                flexDirection: "column",
                                maxWidth: '100%',
                                alignItems: "center"
                            }}>
                                <label>Дата занятия</label>
                                <input onChange={e => setUser(p => ({...p, date: e.target.value}))}
                                       className="main-input" value={user?.date ? user.date : ''}
                                       placeholder="Выбирете дату" type="date" id="date"/>
                            </div>
                            <button className="signup-btn" onClick={() => {
                                if (user.name && user.phone && user.date) firstClass()
                            }}>Записаться
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="achievments">
            <section className="ac-main">
                <h1 className="xxl-text" style={{margin: 15, maxWidth: '90%', textAlign: "center"}}>Тренерство и <span
                    className="pink-text">образование</span></h1>
                <div style={{
                    display: "flex",
                    justifyContent: "space-around",
                    flexWrap: "wrap",
                    alignItems: "center",
                    marginBlock: 25
                }}>
                    <div style={{display: "flex", flexDirection: 'column', alignItems: "center", maxWidth: '90%'}}>
                        <p className="island" style={{fontSize: '0.75rem', margin: 5}}>
                            Дарья Дубова
                            окончила «Национальный государственный Университет физической культуры, спорта и здоровья им
                            П.Ф. Лесгафта, Санкт-Петербург».<br/>
                            Дарья начинала свой тренерский путь в Центре Гимнастики Ирины Винер-Усмановой в 2016-ом году
                            и
                            проработала там до 2022-го года.<br/>
                            На сегодняшний день работает в Спортивном клубе «Призвание» город Красногорск.<br/>
                            А так же Дарья имеет первую судейскую категорию и стаж работы тренером составляет 7
                            лет.<br/>
                            За годы тренировок с Дарьей Вадимовной, ее ученицы добились достойных успехов выступая на
                            всероссийских соревнованиях, а так же принимали участие в показательных номерах, «30-ти
                            секундах» на «Гран-при», среди лучших маленьких гимнасток России.
                        </p>
                        <p className="island" style={{fontSize: '0.8rem'}}>
                            &ndash; Проведено на высшем уровне 50 тренировочных сборов
                            <br/><br/>&ndash; 10 мастер классов по всей России
                            <br/><br/>&ndash; 30 спортсменок из разных уголков Мира и России, с которыми она работает 4
                            года в онлайн формате - свыше 3.000 часов тренировок.
                        </p>
                    </div>
                    <div style={{display: "flex", flexDirection: 'column', alignItems: "center"}}>
                        <div className="slider">
                            {images.map(el => {
                                return <div className="item_2">
                                    <img className="img-square-cutted" src={el} alt={el}/>
                                </div>
                            })}
                            <a className="previous" id="previous_2">&lt;</a>
                            <a className="next" id="next_2">&gt;</a>
                        </div>
                        <p className="island" style={{fontSize: '0.9rem'}}>
                            Так же с 2019 года, Дарья проводит свои турниры в Воронеже и Москве. <br/>
                            "Межрегиональный турнир на призы Чемпионки Юношеских Олимпийских Игр Дарьи Дубовой -
                            Достижение».
                            Каждый Турнир собирает более 300 участниц из разных городов нашей страны.
                        </p>
                    </div>
                </div>
            </section>
        </section>
        <section>
            <section style={{width: 600, margin: '0 auto', maxWidth: "90%"}}>
                <h1 className="xxl-text" style={{textAlign: "center", marginBlock: 10}}>Отзывы</h1>
                <div className="slider" style={{marginBlock: 25}}>
                    <div className="item_3 island" style={{margin: '0 auto', height: 350}}>
                        <div>
                            <img style={{marginInline: 2}} src={star} width='15'/>
                            <img src={star} width='15'/>
                            <img src={star} width='15'/>
                            <img src={star} width='15'/>
                            <img src={star} width='15'/>
                        </div>
                        <h2 style={{marginTop: 10}}>Евгений П.</h2>
                        <h4 style={{marginBottom: 10}}>г. Липецк</h4>
                        <p style={{fontSize: '0.8rem'}}>
                            Тренировки построены очень грамотно и профессионально.
                            Несмотря на то, что проходят они в онлайн-режиме, тренер
                            внимательно следит за правильно техникой, исправляет. Обращает
                            внимание на самые небольшие нюансы, что должно привести нашу
                            гимнастку к хорошим результатам. И сами тренировки проходят
                            в дружелюбной атмосфере, что очень важно для ребенка.
                        </p>
                    </div>
                    <div className="item_3 island" style={{margin: '0 auto', height: 350}}>
                        <div>
                            <img style={{marginInline: 2}} src={star} width='15'/>
                            <img src={star} width='15'/>
                            <img src={star} width='15'/>
                            <img src={star} width='15'/>
                            <img src={star} width='15'/>
                        </div>
                        <h2 style={{marginTop: 10}}>Ольга В.</h2>
                        <h4 style={{marginBottom: 10}}>г. Краснознаменск</h4>
                        <p>
                            Пришли к Вам НИКАКИЕ от слова СОВСЕМ)))Вы дали хороший
                            старт,научили базовым элементам,познакомили с предметом и
                            влюбили Вику в себя!Всегда будем благодарны {/*<img width='19' src='https://emojigraph.org/media/72/apple/red-heart_2764-fe0f.png' />
                            <img width='19' src='https://emojigraph.org/media/apple/cherry-blossom_1f338.png' />
                            <img width='19' src='https://emojigraph.org/media/apple/cherry-blossom_1f338.png' />
                        <img width='19' src='https://emojigraph.org/media/apple/cherry-blossom_1f338.png' />*/}
                        </p>
                    </div>
                    <div className="item_3 island" style={{margin: '0 auto', height: 350}}>
                        <div>
                            <img style={{marginInline: 2}} src={star} width='15'/>
                            <img src={star} width='15'/>
                            <img src={star} width='15'/>
                            <img src={star} width='15'/>
                            <img src={star} width='15'/>
                        </div>
                        <h2 style={{marginTop: 10}}>Елена М.</h2>
                        <h4 style={{marginBottom: 10}}>г. Тамбов</h4>
                        <p style={{fontSize: '0.9rem'}}>За время работы узнали основы и базу предметной подготовки,
                            правильность выполнения элементов, научились понимать правила
                            оценки и стоимость работ и элементов.
                            Так же подтянулись в хореографии , узнали свои сильные и слабые стороны.</p>
                    </div>
                    <div className="item_3 island" style={{margin: '0 auto', height: 350}}>
                        <div>
                            <img style={{marginInline: 2}} src={star} width='15'/>
                            <img src={star} width='15'/>
                            <img src={star} width='15'/>
                            <img src={star} width='15'/>
                            <img src={star} width='15'/>
                        </div>
                        <h2 style={{marginTop: 10}}>Ирина Л.</h2>
                        <h4 style={{marginBottom: 10}}>г. Таганрог</h4>
                        <p> Очень благодарны Дарье Вадимовне , за то, что в далеком пандемийном 2020 году,
                            она поверила в ЧУДО! Благодаря ее вере и настойчивости Виолетты, гимнастика
                            стала нашей жизнью!</p>
                    </div>
                    <div className="item_3 island" style={{margin: '0 auto', height: 350}}>
                        <div>
                            <img style={{marginInline: 2}} src={star} width='15'/>
                            <img src={star} width='15'/>
                            <img src={star} width='15'/>
                            <img src={star} width='15'/>
                            <img src={star} width='15'/>
                        </div>
                        <h2 style={{marginBlock: 10}}>Мария Г.</h2>
                        <h4 style={{marginBottom: 10}}>г. Тамбов</h4>
                        <p style={{fontSize: '0.8rem'}}>
                            За три года прошли много! Характер у дочки тот ещё, но в двоем Вы творите чудеса!
                            Да, ругаете, да... Делаете замечания. Что только не было, даже тренировки мы отменяли, чтобы
                            ребёнок
                            понял, что это ему надо. И в итого ребёнок может и настаивает на своём, но скучает, хочет
                            тренироваться. Да, что сказать... Стала маленькая семья! я очень рада, что судьба нас свела!
                        </p>
                        {/* 
                        <img width='19' src='https://emojigraph.org/media/72/apple/woman-facepalming_1f926-200d-2640-fe0f.png' />
                        <img width='19' src='https://emojigraph.org/media/72/apple/smiling-face-with-hearts_1f970.png' />
                        */}
                    </div>
                    <div className="item_3 island" style={{margin: '0 auto', height: 350}}>
                        <div>
                            <img style={{marginInline: 2}} src={star} width='15'/>
                            <img src={star} width='15'/>
                            <img src={star} width='15'/>
                            <img src={star} width='15'/>
                            <img src={star} width='15'/>
                        </div>
                        <h2 style={{marginBlock: 10}}>Анна Г.</h2>
                        <h4 style={{marginBottom: 10}}>г. Красногорск</h4>
                        <p style={{fontSize: '0.8rem'}}>
                            Дарья Вадимовна настоящий профессионал своего дела,внимательный и сопереживающий
                            тренер,который поддерживает
                            и верит в своих учениц,но при этом не даёт им спуска!Дочка пришла к Дарье Вадимовне меньше
                            полугода назад в
                            возрасте 7 лет и без особого желания заниматься гимнастикой,сейчас уже есть огромный
                            прогресс!
                        </p>
                    </div>
                    <div className="item_3 island" style={{margin: '0 auto', height: 365}}>
                        <div>
                            <img style={{marginInline: 2}} src={star} width='15'/>
                            <img src={star} width='15'/>
                            <img src={star} width='15'/>
                            <img src={star} width='15'/>
                            <img src={star} width='15'/>
                        </div>
                        <h2 style={{marginBlock: 10}}>Ольга П.</h2>
                        <h4 style={{marginBottom: 10}}>г. Красногорск</h4>
                        <p style={{fontSize: '0.8rem'}}>
                            Хочется выразить слова благодарности, за любовь , вклад в спортивную подготовку наших детей
                            и кропотливый труд.
                            Всегда найдёте подход к каждому ребёнку, Алиса обожает Вас. За год Алиса значительно
                            подтянулась,так же начала
                            работать с предметами.
                            Дарья Вадимовна, тренер работающий на результат и этот результат мы видим на своих детях!
                            Спасибо Вам огромное за Всё.
                        </p>
                    </div>
                    <div className="item_3 island" style={{margin: '0 auto', height: 370}}>
                        <div>
                            <img style={{marginInline: 2}} src={star} width='15'/>
                            <img src={star} width='15'/>
                            <img src={star} width='15'/>
                            <img src={star} width='15'/>
                            <img src={star} width='15'/>
                        </div>
                        <h2 style={{marginBlock: 10}}>Елена К.</h2>
                        <h4 style={{marginBottom: 10}}>г. Челябинск</h4>
                        <p style={{fontSize: '0.8rem'}}>
                            После начала онлайн занятий начали понимать, что 5 лет ходили на гимнастику закреплять
                            ошибки. Очень сложно было переучивать буквально всё.
                            Спасибо Дарье Вадимовне за грамотную работу, терпение и веру в ребенка. После полугода
                            занятий привили привычку тянуть стопы и ноги,
                            выучили на зубок технику выполнения некоторых элементов тела, параллельно изучаем технику
                            владения предметом.
                        </p>
                    </div>
                    <a className="previous_" id="previous_3">&lt;</a>
                    <a className="next_" id="next_3">&gt;</a>
                </div>
            </section>
        </section>
    </div>
}