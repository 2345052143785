import React, {useEffect, useState} from "react";
import Navbar from "./Nav";
import axios from "axios";
import {Helmet} from "react-helmet";
import logo from '../lib/other/logo.png'
import '../Pages/diary.css'
import ReactDOM from "react-dom/client";
import {Form} from "react-router-dom";

export default function Calendar(props) {
    const thisPage = `https://dnevnik-rg.ru/api`
    const [user, setUser] = useState({})
    const [calendar, setCalendar] = useState([])
    const [studentCR, setStudentCR] = useState()
    const [fstPre, setFSTPRE] = useState(false)
    const [studentSEC, setStudentSEC] = useState()
    const [all, setAll] = useState()
    const [timeCR, setTimeCR] = useState('07.00')
    const [durationCR, setDurationCR] = useState('00.30')
    const [priceCR, setPriceCR] = useState(1600)
    const [activeID, setActiveId] = useState()
    const [fixedStudents, setFixedStudents] = useState([])
    const [isRequested, setRequested] = useState(false)
    const [period, setPeriod] = useState(1)
    const [paymentPhoto, setPaymentPhoto] = useState()
    const date = new Date()
    let thisMonth = (date.getMonth() + 1 > 9) ? date.getMonth() + 1 : "0" + ((date.getMonth() + 1)).toString()
    let b = [], a = []
    for (let i = 0; i <= 59; ++i) {
        b.push({
            m: new Date(date.getTime() + i * 86400000).getMonth() + 1 > 9 ? new Date(date.getTime() + i * 86400000).getMonth() + 1 : '0' + (new Date(date.getTime() + i * 86400000).getMonth() + 1),
            d: new Date(date.getTime() + i * 86400000).getDate(),
            i: i
        })
    }
    for (let i = 0, lastTime = '06.30'; i < 27; ++i, lastTime = addTimes([lastTime, '00.30'])) {
        a.push({d: addTimes([lastTime, '00.30']), i: i})
    }
    const [dates] = useState(b)
    const [times] = useState(a)
    const [duration] = useState([
        {d: '00.30', i: 0, p: 1600}, {d: '01.00', i: 1, p: 3200},
        {d: '01.30', i: 2, p: 4800}, {d: '02.00', i: 3, p: 6400}
    ])

    function createClass(dt) {
        if (studentCR !== undefined) {
            axios.post(`${thisPage}/main.php?type=crClass&id=${localStorage.getItem('clid')}&dur=${durationCR}&student=${user.role === 'student' ? (user.name + ' ' + user.sname) : studentCR}&time=${timeCR}&price=${priceCR}&date=${dt}${studentSEC !== undefined ? `&ss=` + studentSEC : ""}`)
                .then(resp => {
                    if (resp.data.ok) window.location.href = ''
                    else if (!resp.data.ok) {
                        alert("Error detected\nCode: " + resp.data.type)
                    }
                })
        }
    }

    function getMonthName(monthNumber) {
        const date = new Date();
        date.setMonth(monthNumber - 1);
        return date.toLocaleString([], {month: 'long'});
    }

    function getFixedStudents() {
        axios.get(`${thisPage}/main.php?type=getCoachStudents&coach=${localStorage.getItem('clid')}`)
            .then(response => {
                if (response.data.ok) {
                    setFixedStudents(response.data.data)
                } else {
                    alert('Произошла ошибка загрузки учениц')
                }
                setFSTPRE(true)
            })
    }

    function today() {
        let today = new Date()
        let dd = String(today.getDate()).padStart(2, '0')
        let mm = String(today.getMonth() + 1).padStart(2, '0')
        let yyyy = today.getFullYear()
        today = yyyy + '-' + mm + '-' + dd
        return today
    }

    function getDayOfWeek(date) {
        const dayOfWeek = new Date(date).getDay();
        return isNaN(dayOfWeek) ? null :
            ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'][dayOfWeek];
    }

    function getClasses() {
        axios.get(`${thisPage}/main.php?type=getClassesForWeek&s=${date.getFullYear()}-${thisMonth}-${date.getDate()}&id=${localStorage.getItem('clid')}`)
            .then(resp => {
                resp.data.ok ? setCalendar(resp.data.data) : console.log('<No classes imported>')
            })
    }

    function deleteClass(id) {
        axios.get(`${thisPage}/main.php?type=delClass&id=${localStorage.getItem('clid')}&classId=${id}`)
            .then(resp => {
                if (resp.data.ok) window.location.href = ''
                else if (!resp.data.ok) {
                    alert("Error detected\nCode: " + resp.data.type)
                }
            })
    }

    function studentCreateClass(date) {
        const data = new FormData()
        data.append('payment-screen', paymentPhoto)
        axios.post(`${thisPage}/main.php?type=createStudentClass&date=${date.split('-')[2]}-${date.split('-')[1]}-${date.split('-')[0]}&student=${user?.name + ' ' + user?.sname}&coach=${user?.fixed_trainer}&timePer=${timeCR}-${addTimes([timeCR, durationCR])}&price=${priceCR}&dur=${durationCR}&time=${timeCR}`, data)
            .then(res => {
                if (res.data.ok) window.location.href = ''
                else {
                    alert('Произошла ошибка создания занятия')
                    console.log(res.data)
                }
            })
    }

    function availClasses() {
        axios.get(`${thisPage}/main.php?type=availClasses&dayId=${date.getFullYear()}-${thisMonth}-${dates[0].d}&coach=${user.role === 'student' ? user.fixed_trainer : user.public_key}`)
            .then(rs => {
                if (rs.data.ok) {
                    let newDates = []
                    setAll(rs.data.data)
                } else console.log(rs.data.type)
            })
        setRequested(true)
    }

    const catchPhoto = e => {
        if (e.target.files) {
            setPaymentPhoto(e.target.files[0])
        }
    }

    function addTimes(timeArray) {
        let mins = timeArray.reduce((acc, time) => {
            let [h, m] = time.split('.');
            acc += h * 60 + m * 1;
            return acc;
        }, 0);
        return (mins / 60 | 0) + '.' + ('0' + (mins % 60)).slice(-2);
    }

    function clearAddTimes(time) {
        return time.split('.')[0] < 10 ? '0' + time.split('.')[0] + '.' + time.split('.')[1] : time
    }

    useEffect(() => {
        getClasses()
        user?.role !== 'student' && getFixedStudents()
    }, [])
    if (!user.name) setUser(props.user)
    if (user?.name && !fstPre) {
        user?.role !== 'student' && getFixedStudents()
    }
    if (!all && !isRequested && user.name && b.length > 0) availClasses()
    return <div className="App">
        <Helmet>
            <title>Календарь</title>
            <link rel="shortcut icon" href={logo}/>
        </Helmet>
        <div className="calendar__">
            <h1 style={{textAlign: "center"}}>Календарь</h1>
            <h3 style={{textAlign: "center"}}>Период: <select style={{marginBlock: 5}} onChange={
                e => setPeriod(e.target.value)}>
                <option selected value='1'>1 день</option>
                <option value='7'>7 дней</option>
                <option value='30'>30 дней</option>
                <option value='60'>60 дней</option>
            </select></h3>
            <h4 style={{color: "grey", textAlign: "center"}}>Нажмите, чтобы выбрать период</h4>
            {dates.slice(0, Number(period)).map(el => {
                return <section>
                    <div className="day__calen_c"
                         style={user?.role === 'student' ? {width: "auto", flexWrap: "wrap"} : {flexWrap: "wrap"}}>
                        <div style={{
                            fontWeight: 500,
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "center",
                            flexDirection: "column",
                            width: 140
                        }}>
                            <h3>{getDayOfWeek(Date.now() + 86000000 * el.i)}</h3>
                            <h3>{el.d}.{el.m}.{date.getFullYear()}</h3>
                        </div>
                        <div style={user?.role === 'student' ? {} : {margin: 10}}>
                            {user?.role !== 'student' ?
                                <div className="k3m12" style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: 5,
                                    justifyContent: 'center',
                                    flexWrap: "wrap"
                                }}>
                                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                        {user.role === 'student' ?
                                            <h2 style={{color: "grey", justifySelf: "center"}}>Тренер</h2> :
                                            <select onChange={e => {
                                                setStudentCR(e.target.value)
                                                setActiveId(el.i)
                                            }}
                                                    className="input"
                                                    style={{width: 250, maxWidth: '85%'}}
                                                    id={activeID}
                                                    value={activeID === el.i ? studentCR : ''}>
                                                <option selected value={undefined}>Первая ученица</option>
                                                {fixedStudents.map(st => {
                                                    return <option value={st.name + ' ' + st.sname}>
                                                        {st.name + ' ' + st.sname}
                                                    </option>
                                                })}
                                            </select>}
                                        {user?.role === 'student' ?
                                            <React.Fragment></React.Fragment> :
                                            <select onChange={e => {
                                                setStudentSEC(e.target.value)
                                                setActiveId(el.i)
                                            }}
                                                    className="input"
                                                    style={{width: 250, maxWidth: '85%'}}
                                                    id={activeID}
                                                    value={activeID === el.i ? studentSEC : ''}>
                                                <option selected value={undefined}>Вторая ученица</option>
                                                {fixedStudents.map(st => {
                                                    return <option value={st.name + ' ' + st.sname}>
                                                        {st.name + ' ' + st.sname}
                                                    </option>
                                                })}
                                            </select>}
                                    </div>
                                    <select
                                        onChange={e => {
                                            setTimeCR(e.target.value);
                                            setActiveId(el.i)
                                        }} id={activeID} value={activeID === el.i ? timeCR : ''}
                                        className="input"
                                        style={{margin: 0, width: 100, marginInline: 5, marginTop: 5}}>
                                        <option style={{fontSize: '1.0rem'}} selected disabled>Время</option>
                                        {times?.map(tm => {
                                            let timeAvail = true
                                            if (all) {
                                                all.map(al => {
                                                    let ad = al.date === `${date.getFullYear()}-${el.m}-${(el.d > 9) ? el.d : "0" + (el.d)}`
                                                    if (ad && (tm.d >= al.time && tm.d < addTimes([al.time, al.duration])) && al.state === "1") timeAvail = false
                                                })
                                                if (timeAvail) return <option style={{fontSize: '1.0rem'}}
                                                                              value={tm.d}>{tm.d}</option>
                                            } else return <option
                                                key={tm.i}
                                                style={{fontSize: '1.0rem'}}
                                                value={tm.d}>{tm.d}
                                            </option>
                                        })}
                                    </select>
                                    <select
                                        onChange={e => {
                                            setDurationCR(e.target.value)
                                            setActiveId(el.i)
                                            setPriceCR(duration.filter(t => t.d === e.target.value)[0].p)
                                        }} id={activeID} value={activeID === el.i ? durationCR : ''}
                                        className="input"
                                        style={{margin: 0, width: 100, marginInline: 5, marginTop: 5}}>
                                        <option style={{fontSize: '1.0rem'}} selected disabled>Длительность</option>
                                        {duration.map(tm => {
                                            return <option key={tm.i} style={{fontSize: '1.0rem'}}
                                                           value={tm.d}>{tm.d}</option>
                                        })}
                                    </select>
                                    <button onClick={() => {
                                        createClass(`${date.getFullYear()}-${(el.m > 9) ? el.m : "0" + (el.m)}-${el.d}`)
                                    }} style={{width: 'auto', marginBottom: 0, marginTop: 5}}>Создать
                                    </button>
                                </div> : <div>
                                    <select
                                        onChange={e => {
                                            setTimeCR(e.target.value);
                                            setActiveId(el.i)
                                        }} id={activeID} value={activeID === el.i ? timeCR : ''}
                                        className="input"
                                        style={{margin: 0, width: 100, marginInline: 5, marginTop: 5}}>
                                        <option style={{fontSize: '1.0rem'}} selected disabled>Время</option>
                                        {times.map(tm => {
                                            let timeAvail = true
                                            if (all) {
                                                all.map(al => {
                                                    let ad = al.date === `${date.getFullYear()}-${el.m}-${(el.d > 9) ? el.d : "0" + (el.d)}`
                                                    if (ad && (tm.d >= al.time && tm.d < addTimes([al.time, al.duration])) && al.state === "1") timeAvail = false
                                                })
                                                if (timeAvail) return <option style={{fontSize: '1.0rem'}}
                                                                              value={tm.d}>{tm.d}</option>
                                            } else return <option
                                                key={tm.i}
                                                style={{fontSize: '1.0rem'}}
                                                value={tm.d[0] > 2 ? tm.d = '0' + tm.d : tm.d}>{tm.d[0] > 2 ? tm.d = '0' + tm.d : tm.d}</option>
                                        })}
                                    </select>
                                    <select
                                        onChange={e => {
                                            setDurationCR(e.target.value)
                                            setActiveId(el.i)
                                            setPriceCR(duration.filter(t => t.d === e.target.value)[0].p)
                                        }} id={activeID} value={activeID === el.i ? durationCR : ''}
                                        className="input"
                                        style={{margin: 0, width: 100, marginInline: 5, marginTop: 5}}>
                                        <option style={{fontSize: '1.0rem'}} selected disabled>Длительность</option>
                                        {duration.map(tm => {
                                            return <option key={tm.i} style={{fontSize: '1.0rem'}}
                                                           value={tm.d}>{tm.d}</option>
                                        })}
                                    </select>
                                    <button onClick={() => {
                                        if (paymentPhoto) studentCreateClass(`${date.getFullYear()}-${el.m}-${(el.d > 9) ? el.d : "0" + (el.d)}`)
                                        else alert("Для создания занятия необходимо прикрепить скриншот об оплате")
                                    }} style={paymentPhoto ? {
                                        width: 'auto',
                                        marginBottom: 0,
                                        marginTop: 5
                                    } : {width: 'auto', marginBottom: 0, marginTop: 5, background: 'lightgrey'}}>
                                        Создать
                                    </button>
                                    <div>
                                        <label for='payment-screen' onClick={() => setActiveId(el.i)} style={{color: "blue", fontSize: 14}}>Прикрепить
                                            скриншот оплаты</label>
                                        <input onChange={catchPhoto} accept="image/*" style={{display: "none"}}
                                               name='payment-screen'
                                               id='payment-screen' type='file'/>
                                    </div>
                                </div>}
                        </div>

                        <div>
                            {paymentPhoto && activeID === el.i &&
                                <img style={{objectFit: "cover", width: 300, height: 400, borderRadius: 20,}}
                                     alt='paymentPhoto'
                                     src={URL.createObjectURL(paymentPhoto)}/>}
                        </div>
                    </div>
                    {
                        calendar?.map(d => {
                            if (d.date === `${date.getFullYear()}-${el.m}-${(el.d > 9) ? el.d : "0" + (el.d)}`) {
                                return <div className="day__calen_c">
                                    <div style={{textAlign: "center"}}>
                                        {d.state === '1' ? ((date.getHours() + '.' + date.getMinutes()) > clearAddTimes(addTimes([d.time, d.duration])) && (new Date(today()).getTime() >= new Date(d.date).getTime()) ?
                                                <h4 style={{color: "green"}}>Занятие завершено</h4> :
                                                <React.Fragment></React.Fragment>) :
                                            <h4 style={{color: "orange"}}>Занятие отменено</h4>}
                                    </div>
                                    <div style={{textAlign: "center"}}>
                                        <h4 style={{color: "grey"}}>{user?.role === 'student' ? 'Тренер' : "Ученица"}:</h4>
                                        <h3 id="nmj2k">{user.role === 'student' ? d.coach : d.student}</h3>
                                    </div>
                                    <div style={{textAlign: "center"}}>
                                        <h4 style={{color: "grey"}}>Время:</h4>
                                        <h3 id="nmj2k">{d.time} - {addTimes([d.time, d.duration])}</h3>
                                    </div>
                                    <div style={{textAlign: "center"}}>
                                        <h4 style={{color: "grey"}}>Цена:</h4>
                                        <h3 id="nmj2k">{d.price} ₽</h3>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "center",}}>
                                        {user.role !== 'student' ? <React.Fragment>
                                            <button style={{
                                                width: 'auto',
                                                marginBottom: 0,
                                                backgroundColor: 'rgba(255, 0, 0, .7)',
                                                color: 'white'
                                            }} onClick={() => {
                                                deleteClass(d.id)
                                            }}>Удалить
                                            </button>
                                            {d.state === '1' ? <button style={{
                                                width: 'auto',
                                                marginBottom: 0,
                                                backgroundColor: 'rgba(255,124,20, .7)',
                                                color: 'white'
                                            }} onClick={() => {
                                                let text = `${user.name} ${user.sname}
                                             отменила занятие на ${d.date.split('-')[2]} ${getMonthName(d.date.split('-')[1])} ${d.date.split('-')[0]} и время ${d.time} - ${addTimes([d.time, d.duration])}`
                                                axios.get(`${thisPage}/main.php?type=addNotif&
                                            to=${user.fixed_trainer}&from=${user.public_key}&date=${d.date}&
                                            typ=${text}`)
                                                    .then(response => {
                                                        if (!response.data.ok) alert('Произошла ошибка отмены занятия')
                                                        else {
                                                            axios.get(`${thisPage}/main.php?type=cancelClass&classId=${d.id}`)
                                                                .then(response => {
                                                                    if (!response.data.ok) alert('Произошла ошибка отмены занятия')
                                                                    else window.location.href = ''
                                                                })
                                                        }
                                                    })
                                            }}>
                                                Отменить
                                            </button> : <React.Fragment></React.Fragment>}
                                        </React.Fragment> : d.state === '1' ? <button style={{
                                            width: 'auto',
                                            marginBottom: 0,
                                            backgroundColor: 'rgba(255,124,20, .7)',
                                            color: 'white'
                                        }} onClick={() => {
                                            let text = `${user.name} ${user.sname}
                                             отменила занятие на ${d.date.split('-')[2]} ${getMonthName(d.date.split('-')[1])} ${d.date.split('-')[0]} и время ${d.time} - ${addTimes([d.time, d.duration])}`
                                            axios.get(`${thisPage}/main.php?type=addNotif&
                                            to=${user.fixed_trainer}&from=${user.public_key}&date=${d.date}&
                                            typ=${text}`)
                                                .then(response => {
                                                    if (!response.data.ok) alert('Произошла ошибка отмены занятия')
                                                    else {
                                                        axios.get(`${thisPage}/main.php?type=cancelClass&classId=${d.id}`)
                                                            .then(response => {
                                                                if (!response.data.ok) alert('Произошла ошибка отмены занятия')
                                                                else window.location.href = ''
                                                            })
                                                    }
                                                })
                                        }}>
                                            Отменить
                                        </button> : <React.Fragment></React.Fragment>}
                                    </div>
                                </div>
                            }
                        })
                    }
                </section>
            })}
        < /div>
        <Navbar usertype={props.role} fixedT={user?.fixed_trainer}/>
    </div>
}