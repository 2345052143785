import {Routes, Route} from "react-router-dom"
import Auth from "./Pages/Auth"
import AdminDiary from "./Pages/AdminDiary"
import Exit from "./Pages/Exit"
import Pages from "./Pages/Pages"
import './Pages/diary.css'
import Account from "./Pages/Account"
import Main from "./Pages/Main"
import StudentAnalytics from "./Pages/StudentAnalytics";
import Notifications from "./Pages/Notifications";

export default function App() {
    return (
        <Routes>
            <Route path='/' element={<Main/>}/>
            <Route path='/auth' element={<Auth/>}/>
            <Route path='/exit_session' element={<Exit/>}/>
            <Route path='/diary/' element={<AdminDiary/>}/>
            <Route path='/notifications/' element={<Notifications/>}/>
            <Route path='/sa/' element={<StudentAnalytics/>}/>
            <Route path='/diary/:pagetype' element={<Pages/>}/>
            <Route path='/acc/:clid' element={<Account/>}/>
        </Routes>
    );
}